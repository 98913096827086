<template>
  <div id="app" :class="'splittest-v' + $store.state.splittest_version">
    <section class="bg-white p-2 p-md-3">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center p-2 p-md-3 headbalk">
            <b>0% BTW!</b> Voor particulieren is de aanschaf per 1 januari 2023
            <u>BTW-vrij</u>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-white">
      <div class="container p-0 mb-md-2">
        <div class="row justify-content-center p-0 m-0">
          <div class="col-7 col-md-2 p-2 m-0 text-center text-md-left">
            <router-link :to="{ name: 'index' }">
              <img
                src="@/assets/logo.png"
                alt="Zonnestroom Collectief"
                class="mb-2 mb-md-0"
              />
            </router-link>
          </div>
          <div class="col-12 col-md-10 p-2 m-0 text-right align-self-center">
            <span class="d-block d-md-inline-block px-2 px-md-5 text-center">
              <font-awesome-icon
                :icon="['far', 'piggy-bank']"
                class="mr-2"
              />Installatietijd circa 4 a 6 weken!
            </span>
          </div>

          <div
            class="col-12 p-2 bg-primary d-block d-md-none text-white text-center text-uppercase font-weight-bold"
          >
            Direct uit eigen voorraad beschikbaar!
          </div>
        </div>
      </div>
    </section>

    <section class="bg-1 bg-cover">
      <div class="bg-pattern">
        <div class="py-0 py-md-5 container position-relative">
          <div class="row">
            <div class="cirkel text-center text-white position-absolute">
              <p class="p-0 m-0 text-uppercase">
                <b class="d-block p-0 m-0 cirkel2">UIT EIGEN</b>
                <b class="d-block p-0 m-0 cirkel2">VOORRAAD</b>
                <b class="d-block p-0 m-0 cirkel1">LEVERBAAR</b>
              </p>
            </div>

            <div class="col-12 col-md-6 p-3 m-0 pr-md-5 text-white">
              <h2 class="pb-2">
                Zonnepanelen direct beschikbaar uit eigen voorraad!
              </h2>
              <p>
                <a
                  href="https://www.zonnestroomcollectief.nl"
                  target="_blank"
                  class="font-weight-bold text-white"
                  >Zonnepanelen kopen</a
                >
                is goed voor het milieu en uw portemonnee. De investering kunt u
                er in een jaar of 3 jaar uit hebben door de exorbitant gestegen
                energieprijzen. En daarna heeft u gratis stroom van eigen dak.
                Als u overweegt om zonnepanelen te kopen, wilt u natuurlijk wel
                de scherpste prijs ontvangen!
              </p>

              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />De <b><u>scherpste prijs</u></b>
                van Nederland
              </p>

              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                /><b><u>A-merk</u></b> kwaliteit zonnepanelen
              </p>
              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />Gemiddelde <b><u>terugverdientijd van slechts 3</u></b> jaar!
              </p>

              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />De <b><u>waarde van uw woning</u></b> zal stijgen
              </p>

              <p class="p-0 p-md-2 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />Gemiddeld <b><u>30+% rendement</u></b> op uw investering
              </p>

              <p class="p-0 p-md-2 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />Schitterende <b><u>volledige zwarte</u></b> zonnepanelen
              </p>

              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                /><b><u>In één dag</u></b> alles geïnstalleerd
              </p>
              <p class="p-0 p-md-1 m-0 d-none d-md-block">
                <font-awesome-icon
                  :icon="['far', 'check']"
                  class="text-success mr-2"
                />
                Installatietijd <b><u>circa 4 a 6 weken!</u></b> Wacht niet
                langer
              </p>
            </div>
            <div class="col-12 col-md-6 p-3 p-md-5 bg-white rounded">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-0 m-0 bg-info">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-6 col-md-2 p-3 px-md-4 py-md-5">
            <img src="@/assets/keurmerk1.png" alt="keurmerk" class="" />
          </div>
          <div class="col-6 col-md-2 p-3 px-md-4 py-md-5">
            <img src="@/assets/keurmerk2.png" alt="keurmerk" class="" />
          </div>
          <div class="col-6 col-md-2 p-3 px-md-4 py-md-5">
            <img src="@/assets/keurmerk3.png" alt="keurmerk" class="" />
          </div>
          <div class="col-6 col-md-2 p-3 px-md-4 py-md-5">
            <img src="@/assets/keurmerk4.png" alt="keurmerk" class="" />
          </div>
        </div>
      </div>
    </section>

    <section class="p-2 pt-4 pt-md-5 pb-4 p-md-5 bg-primary recensies">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <h2 class="font-weight-bold text-center p-0 m-0 mb-4 text-white">
              Wat klanten over ons vertellen
            </h2>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-4 py-1">
            <div class="p-3 p-md-4 bg-white rounded h-100 text-center">
              <img
                src="@/assets/annie.png"
                alt="Annie"
                class="profielfoto text-center d-block mx-auto mb-3"
              />

              <b>
                <a href="https://www.zonnestroomcollectief.nl" target="_blank"
                  >Zonnestroom Collectief</a
                >
                bedankt!</b
              >
              <p class="p-0 m-0">
                <i
                  >‘Zeer kundige monteurs, en als u een vraag heeft wordt het
                  snel en kundig opgelost. Ook heb ik zeker die scherpe prijs
                  ontvangen!’</i
                >
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 py-1">
            <div class="p-3 p-md-4 bg-white rounded h-100 text-center">
              <img
                src="@/assets/stefan.png"
                alt="Stefan"
                class="profielfoto text-center d-block mx-auto mb-3"
              />
              <b>12 mooie top panelen!</b>
              <p class="p-0 m-0">
                <i
                  >‘De 12 zonnepanelen zijn door 3 monteurs binnen een paar uur
                  geplaatst. Snel en netjes gewerkt. Ben erg tevreden’</i
                >
              </p>
            </div>
          </div>
          <div class="col-12 col-md-4 py-1">
            <div class="p-3 p-md-4 bg-white rounded h-100 text-center">
              <img
                src="@/assets/mandy.png"
                alt="Mandy"
                class="profielfoto text-center d-block mx-auto mb-3"
              />
              <b>Ik zou ze aanbevelen!</b>
              <p class="p-0 m-0">
                <i
                  >‘Goede service en communicatie, deskundige installatie en
                  prima administratieve afwikkeling. Fijn bedrijf!’</i
                >
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-2 py-4 p-md-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <h2 class="font-weight-bold text-center p-0 m-0">Hoe het werkt</h2>
            <p class="font-weight-light text-center p-0 m-0 mb-5">
              Zonnestroom Collectief helpt u graag bij kiezen voor de
              zonnepanelen oplossing die bij u past!<br
                class="d-none d-md-block"
              />
              Dit is het stappenplan:
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-3 mb-5 mb-md-0">
            <div class="p-3 p-md-4 bg-secondary text-white text-center h-100">
              <font-awesome-icon
                size="5x"
                :icon="['far', 'file-alt']"
                class="bg-danger text-white mt-n5 p-3 shadow mb-4 rounded d-block w-50 mx-auto"
              />

              <h4 class="font-weight-bold p-0 m-0 text-white text-center">
                Vul het formulier in en ontvang meer info
              </h4>
              <p class="font-weight-light p-0 m-0 text-white text-center"></p>
              Binnen 1 minuut heeft u ons formulier eenvoudig ingevuld!
            </div>
          </div>
          <div class="col-12 col-md-3 mb-5 mb-md-0">
            <div class="p-3 p-md-4 bg-secondary text-white text-center h-100">
              <font-awesome-icon
                size="5x"
                :icon="['far', 'phone-alt']"
                class="bg-danger text-white mt-n5 p-3 shadow mb-4 rounded d-block w-50 mx-auto"
              />

              <h4 class="font-weight-bold p-0 m-0 text-white text-center">
                Wij nemen met u contact op
              </h4>
              <p class="font-weight-light p-0 m-0 text-white text-center">
                We kijken samen of uw dak geschikt is en nemen vrijblijvend alle
                opties met u door.
              </p>
            </div>
          </div>
          <div class="col-12 col-md-3">
            <div class="p-3 p-md-4 bg-secondary text-white text-center h-100">
              <font-awesome-icon
                size="5x"
                :icon="['far', 'piggy-bank']"
                class="bg-danger text-white mt-n5 p-3 shadow mb-4 rounded d-block w-50 mx-auto"
              />

              <h4 class="font-weight-bold p-0 m-0 text-white text-center">
                Afspraak inplannen
              </h4>
              <p class="font-weight-light p-0 m-0 text-white text-center">
                Na een positief informatie gesprek komen we graag bij u langs om
                de details verder uit te werken.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-2 py-4 p-md-5 bg-info">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-7 col-md-4 mb-4 mb-md-0 align-self-center">
            <img src="@/assets/panelen.png" alt="Zonnepanelen" />
          </div>
          <div class="col-12 col-md-8">
            <h2 class="font-weight-bold p-0 m-0 pb-2">
              Onze favoriete zonnepanelen
            </h2>
            <p class="font-weight-light p-0 m-0 mb-4">
              U wilt gewoon goede zonnepanelen op uw dak. Duidelijk. En u wilt
              weten wat u dat kost, wat u dat oplevert, hoe het werkt en hoe u
              het regelt. Maar vaak word u overdonderd met een grote grijze wolk
              aan opties, waardoor uw zonnige humeur al snel verdwijnt. Bij
              Zonnestroom Collectief laten we onze klanten liever stralen.
              Daarom houden we het simpel en snel, met twee soorten zonnepanelen
              en een duidelijke uitleg.
            </p>
            <div class="container">
              <div class="row border-bottom p-3">
                <div class="col-1">
                  <font-awesome-icon
                    size="2x"
                    :icon="['far', 'check']"
                    class="text-primary"
                  />
                </div>
                <div class="col-11">
                  <b>URE 410 Wp mono</b>
                  <p>
                    Het URE 410 Wp zonnepaneel is een hoogvermogen 108-cells
                    monokristallijn zonnepaneel. Het zonnepaneel is perfect voor
                    schuine dakvlakken van zowel woningen tot commerciële
                    projecten waarbij de esthetiek erg belangrijk is. is.
                  </p>
                </div>
              </div>

              <div class="row border-bottom p-3">
                <div class="col-1">
                  <font-awesome-icon
                    size="2x"
                    :icon="['far', 'check']"
                    class="text-primary"
                  />
                </div>
                <div class="col-11">
                  <b>RISEN Titan S 400 Wp mono</b>
                  <p>
                    Het RISEN Titan paneel is opgebouwd uit 120 monokristallijn
                    zonnecellen. Het zonnepaneel is perfect voor schuine
                    dakvlakken van zowel woningen tot commerciële projecten
                    waarbij de esthetiek erg belangrijk is.
                  </p>
                </div>
              </div>
              <div class="row border-bottom p-3">
                <div class="col-11">
                  <b
                    >Deze 2 panelen zijn een kleine greep uit ons
                    assortiment.</b
                  >
                  <p>( Wij bieden vrijwel alle Tier-1 panelen aan )</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-2 py-4 p-md-5">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-10">
            <h2 class="font-weight-bold text-center p-0 m-0 pb-2">
              Bekijk en beheer alles vanaf uw telefoon en tablet!
            </h2>
            <p class="font-weight-light text-center p-0 m-0 mb-3">
              Met de Enphase Enlighten mobiele app, kunt u de status van uw
              systeem in één oogopslag bekijken zonder te scrollen en nog steeds
              een duik nemen in de details van uw systeemstatus en -prestaties,
              en dat terwijl u onderweg bent. Eén keer tikken brengt u naar
              grafieken van energieproductie en overzichten van uw
              energieverbruik en opslag (indien van toepassing).
            </p>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-12 col-md-10 text-center">
            <img src="@/assets/enphase_enlighten.png" alt="Rendement" />
          </div>
        </div>
      </div>
    </section>

    <section class="p-2 py-4 p-md-5 bg-secondary">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 col-md-8">
            <h2 class="font-weight-bold text-center p-0 m-0 text-white">
              Heeft u nog vragen?
            </h2>
            <p class="font-weight-light text-center p-0 m-0 mb-4 text-white">
              Wij geven u graag antwoord!
            </p>
          </div>
        </div>
        <div class="row justify-content-center my-2">
          <div
            class="col-12 col-md-8 rounded border border-white p-3 p-md-4 text-white cursor-pointer"
            @click="isHidden1 = !isHidden1"
          >
            <h4 class="p-0 m-0">Is mijn dak geschikt voor zonnepanelen?</h4>
            <p v-if="!isHidden1" class="p-0 m-0">
              Meer dan 90% is geschikt. Na uw aanvraag controleren wij dit voor
              u. We houden hier bijvoorbeeld rekening met hoe oud het dak is,
              welke ligging je dak(vlak) heeft, de locatie in Nederland in
              verband met wind regio's en de hellingshoek. Daarna nemen we samen
              telefonisch uw wensen door om zo het beste zonnedak voor u in te
              tekenen.
            </p>
          </div>
        </div>
        <div class="row justify-content-center my-2">
          <div
            class="col-12 col-md-8 rounded border border-white p-3 p-md-4 text-white cursor-pointer"
            @click="isHidden2 = !isHidden2"
          >
            <h4 class="p-0 m-0">Welke garanties en services krijg ik?</h4>
            <p v-if="!isHidden2" class="p-0 m-0">
              We geven 10 jaar installatiegarantie. Hier valt de werking van het
              gehele systeem onder. Dat beloven we. Gaat er iets stuk binnen de
              installatiegarantie termijn? Dan vervangen we gratis uw
              zonnepanelen, omvormer(s), optimizer(s) en delen van het
              kabeltraject. De productgarantie kan verschillen per merk en type
              product, zo is die van de zonnepanelen veelal langer dan 10 jaar.
            </p>
          </div>
        </div>
        <div class="row justify-content-center my-2">
          <div
            class="col-12 col-md-8 rounded border border-white p-3 p-md-4 text-white cursor-pointer"
            @click="isHidden3 = !isHidden3"
          >
            <h4 class="p-0 m-0">
              Hoe wordt de stroom verrekend met mijn energierekening?
            </h4>
            <p v-if="!isHidden3" class="p-0 m-0">
              U ontvangt van ons na akkoord op uw offerte uitleg over hoe u uw
              systeem kunt aanmelden bij uw netbeheerder (of energieleveren.nl).
              Dit is een eenvoudig proces. Uw zonnedak is dan bekend bij de
              regionale netbeheerder, wat wettelijk verplicht is. Hierna wordt
              deze automatisch aangemeld bij uw energieleverancier en start de
              verrekening en besparing!
            </p>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-2 bg-cover">
      <div class="p-0 py-md-5 container-fluid bg-pattern">
        <div class="row justify-content-end p-0 m-0">
          <div class="col-12 col-md-8 bg-white p-4 p-md-5 m-0">
            <h2 class="font-weight-bold p-0 m-0 pb-2">Wist u dat...</h2>

            <div class="container">
              <div class="row">
                <div class="col-12 col-md-6 p-0 m-0">
                  <p class="font-weight-light p-0 m-0 mr-md-3 mb-3">
                    <font-awesome-icon
                      size="1x"
                      :icon="['far', 'check']"
                      class="text-primary"
                    />
                    De meeste zonnepanelen installaties simpelweg op zolder
                    kunnen worden geïnstalleerd zonder een nieuwe leiding naar
                    de meterkast? Dit doen wij door een zogenaamde PV-verdeler.
                  </p>
                  <p class="font-weight-light p-0 m-0 mr-md-3 mb-3">
                    <font-awesome-icon
                      size="1x"
                      :icon="['far', 'check']"
                      class="text-primary"
                    />
                    De gemiddelde terugverdientijd op dit moment rond de 3 jaar
                    door de exorbitant gestegen energieprijzen.
                  </p>
                </div>
                <div class="col-12 col-md-6 p-0 m-0">
                  <p class="font-weight-light p-0 m-0 ml-md-3 mb-3">
                    <font-awesome-icon
                      size="1x"
                      :icon="['far', 'check']"
                      class="text-primary"
                    />
                    Al onze installaties altijd via een App kunnen worden
                    gevolgd?
                  </p>
                  <img
                    src="@/assets/logo.png"
                    alt="Zonnestroom Collectief"
                    class="p-3 m-0 w-75"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="p-3 p-md-5 bg-primary">
      <div class="container">
        <div class="row justify-content-center text-white">
          <div class="col-12 col-md-4">
            <h1 class="text-center p-0 m-0">A+</h1>
            <h3 class="text-center p-0 m-0 pb-2 pb-md-0">
              Klasse zonnepanelen
            </h3>
          </div>
          <div class="col-12 col-md-4">
            <h1 class="text-center p-0 m-0">10</h1>
            <h3 class="text-center p-0 m-0 pb-2 pb-md-0">
              Jaar fabrieksgarantie
            </h3>
          </div>
          <div class="col-12 col-md-4">
            <h1 class="text-center p-0 m-0">1627</h1>
            <h3 class="text-center p-0 m-0 pb-2 pb-md-0">Woningen voorzien</h3>
          </div>
        </div>
      </div>
    </section>

    <section class="bg-3 bg-cover">
      <div class="bg-pattern">
        <div class="container px-4 py-3 p-md-5">
          <div class="row justify-content-center">
            <div class="col-12 col-md-10 rounded bg-white p-3 p-md-5">
              <router-view />
            </div>
          </div>
        </div>
      </div>
    </section>

    <footer class="py-3 bg-info">
      <div class="container">
        <div class="row">
          <div class="col-12 text-center text-warning">
            <div>
              <p class="p-0 m-0">
                <span
                  class="cursor-pointer font-weight-bold px-1 px-md-3"
                  @click="popupOpen('privacy')"
                  >Privacybeleid</span
                >
              </p>

              <small>
                <a
                  href="https://www.zonnestroomcollectief.nl"
                  target="_blank"
                  class="text-warning"
                  >Zonnestroom Collectief</a
                >
                - Monitorweg 1 - 1322 BJ Almere</small
              >
            </div>
          </div>
        </div>
      </div>
    </footer>

    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn"
      leave-active-class="animated fadeOut"
      mode="out-in"
    >
      <section v-if="popupIsopen" class="popup-container">
        <div class="backdrop" @click="popupDismiss()"></div>

        <div class="container">
          <div class="row justify-content-center">
            <div class="col-12 col-md-12 col-lg-10 py-3 py-md-5">
              <div class="popup bg-white rounded p-3 py-5 p-md-5 p-lg-5">
                <div class="fa" @click="popupDismiss()">
                  <font-awesome-icon size="2x" :icon="['far', 'times']" />
                </div>

                <api-content
                  v-if="popupIsopen"
                  :content-key="'zonnestroom-' + popupIsopen"
                >
                </api-content>

                <button class="btn btn-primary" @click="popupDismiss()">
                  Sluiten
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
import AutoClickID from "@/../../shared/autoclickid.js";
import popup from "@/../../shared/popup.js";

export default {
  components: {
    ApiContent: () => import("@/../../shared/components/ApiContent.vue"),
  },
  mixins: [AutoClickID, popup],
  data() {
    return {
      isHidden1: true,
      isHidden2: true,
      isHidden3: true,
      isHidden4: true,
    };
  },
};
</script>

<style lang="scss">
@import "styles/includes";
@import "styles/bootstrap";
@import "styles/fonts";
@import "styles/styles";

@import "@/../../shared/scss/_popup";

.recensies p {
  line-height: 1.5;
}

.paneel img {
  display: block;
  height: auto;
  width: 100%;
}

.cirkel {
  background-color: $primary;
  width: 175px;
  height: 175px;
  border-radius: 50%;
  display: table;
  font-size: 1.2rem;
  line-height: 1.2;
  z-index: 800;
  top: -10px;
  right: -50px;
  animation: pulse 4s infinite;

  @include media-breakpoint-down(md) {
    top: 200px;
    right: 35px;
    width: 125px;
    height: 125px;
    font-size: 1rem;
    display: none;
  }
}

.cirkel3 {
  font-size: 2.1rem;

  @include media-breakpoint-down(md) {
    font-size: 1.4rem;
  }
}

.cirkel p {
  vertical-align: middle;
  display: table-cell;
}

@keyframes pulse {
  0% {
    transform: scale(0.95) rotate(15deg);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
  }

  70% {
    transform: scale(1.15) rotate(15deg);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    transform: scale(0.95) rotate(15deg);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
}

.headbalk {
  border: 2px solid #000;
  background-color: #ffe400;
  border-radius: 10px;
  font-weight: 300;
  font-size: 1.4rem;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 0.8rem;
    line-height: 1.2;
  }

  b {
    font-weight: 800;

    @include media-breakpoint-down(md) {
      display: block;
      text-align: center;
    }
  }
}
</style>
