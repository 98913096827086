/** @format */

import Vue from "vue";
import Router from "vue-router";
import Newsletter from "./views/Newsletter.vue";
import Inschrijven from "./views/Inschrijven.vue";
import ThankYouPage from "./views/ThankYouPage.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      if (to.hash && /^#[^#]*$/.test(to.hash)) {
        return {
          selector: to.hash,
        };
      }

      return { x: 0, y: 0 };
    }
  },

  routes: [
    {
      path: "/bedankt",
      name: "thank-you",
      component: ThankYouPage,
    },
    {
      path: "/nieuwsbrief",
      name: "nieuwsbrief",
      component: Newsletter,
    },
    {
      path: "",
      alias: "*",
      name: "index",
      component: Inschrijven,
    },
  ],
});
