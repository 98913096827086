export default (to, from, next, store) => {
    if (to.path.substr(0, 2) == '//') {
        return next(false)
    }
    if (to.path) {
        let matches = to.path.match(/\/v(\d+)$/);
        if (matches) {
            store.dispatch("setSplittestVersion", {
                value: matches[1]
            });

        }
    }

    if (to.query.network && parseInt(to.query.network)) {
        store.dispatch("setNetworkId", {
            value: to.query.network
        });

    }

    if (to.query.oa_id) {
        store.dispatch("setTrackingSubid", {
            value: to.query.oa_id
        });
    } else if (to.query.oa_clickid) {
        store.dispatch("setTrackingSubid", {
            value: to.query.oa_clickid
        });
    } else if (to.query.cid) {
        store.dispatch("setTrackingSubid", {
            value: to.query.cid
        });
    }

    if (to.query.fbclid) {
        store.dispatch("setFacebookClickId", {
            value: to.query.fbclid
        });
    }

    if (to.query.site) {
        store.dispatch("setPublisherId", {
            value: to.query.site
        });
    } else if (to.query.pid) {
        store.dispatch("setPublisherId", {
            value: to.query.pid
        });
    }
    if (to.query.ttid) {
        store.dispatch("setTraffictypeId", {
            value: to.query.ttid
        });
    } else if (to.query.traffictype) {
        store.dispatch("setTraffictypeId", {
            value: to.query.traffictype
        });
    }

    if (!store.state.entry_url) {
        store.dispatch("setEntryURL", {
            value: window.location.href
        });
    }
    if (!store.state.referrer && document.referrer) {
        store.dispatch("setReferrer", {
            value: document.referrer
        });

    }

    return next();
};