export default {
    computed: {
        shareUrl() {
            return (
                window.location.protocol +
                "//" +
                window.location.hostname +
                process.env.BASE_URL
            );
        },
        housenumberExtVuetifyItems() {
            var items = [];

            if (this.house_number_possible_ext.indexOf(this.house_number_ext) == -1) {
                items.push({
                    text: 'Kies',
                    value: this.house_number_ext
                });
            }

            for (var ext of this.house_number_possible_ext) {
                items.push({
                    text: ext == 'empty' ? '' : ext,
                    value: ext
                });
            }

            return items;
        },
        selected_pand_info() {
            if (typeof this.BEStreetSearchSelectedDBKey !== 'undefined' && this.BEStreetSearchSelectedDBKey) {
                if (this.house_number_possible_pand_info_be.hasOwnProperty(this.BEStreetSearchSelectedDBKey)) {
                    // flatten object to selected address
                    let r = JSON.parse(JSON.stringify(this.house_number_possible_pand_info_be[this.BEStreetSearchSelectedDBKey]));
                    // if house number exists, overwrite coordinates
                    if (this.house_number) {
                        for (var x of this.house_number_possible_pand_info_be[this.BEStreetSearchSelectedDBKey].addresses) {
                            if (this.BEShowFullHouseNumber(x) == this.house_number) {
                                Object.assign(r, x);
                            }
                        }
                    }

                    r.lat = r["EPSG:4326_lat"];
                    r.lon = r["EPSG:4326_lon"];

                    delete r.addresses;

                    return r;
                }
            }
            for (var x of this.house_number_possible_pand_info) {
                let items = [];
                if (x.huisletter) {
                    items.push(x.huisletter);
                }
                if (x.huisnummertoevoeging) {
                    items.push(x.huisnummertoevoeging);
                }
                let toevoeging = items.join(' ');
                toevoeging = toevoeging === '' ? 'empty' : toevoeging;
                if (toevoeging === this.house_number_ext) {
                    return x;
                }
            };

            if (this.house_number_possible_pand_info && this.house_number_possible_pand_info.length == 1) {
                return this.house_number_possible_pand_info[0];
            }

            return {};
        },
        ziphousenumber: {
            get() {
                return {
                    'zip': this.zip,
                    'house_number': this.house_number,
                    'ext': this.house_number_ext,
                };
            }
        },
        age() {
            if (this.date_of_birth) {
                var x = (this.date_of_birth + '').split('-');
                if (x[0] == '0000' || x[1] == '00' || x[2] == '00') {
                    return null;
                }
                var birthDate = new Date(parseInt(x[0]), parseInt(x[1]) - 1, parseInt(x[2]));
                var today = new Date();
                var age = today.getFullYear() - birthDate.getFullYear();
                var m = today.getMonth() - birthDate.getMonth();
                if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
                    age--;
                }
                return age;

            }
            return null;
        },
        house_number_full() {
            return this.$store.getters.house_number_full;
        },
        house_number: {
            get() {
                return this.$store.state.profile.house_number;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "house_number",
                    value
                });
            }
        },
        house_number_ext: {
            get() {
                return this.$store.state.profile.house_number_ext;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "house_number_ext",
                    value
                });
            }
        },
        house_number_possible_pand_info: {
            get() {
                return this.$store.state.profile.house_number_possible_pand_info;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "house_number_possible_pand_info",
                    value
                });
            }
        },
        house_number_possible_pand_info_be: {
            get() {
                return this.$store.state.profile.house_number_possible_pand_info_be;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "house_number_possible_pand_info_be",
                    value
                });
            }
        },
        house_number_possible_ext: {
            get() {
                return this.$store.state.profile.house_number_possible_ext.map((value, index, array) => {
                    return value == '' ? 'empty' : value;
                });
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "house_number_possible_ext",
                    value
                });
            }
        },
        street: {
            get() {
                return this.$store.state.profile.street;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "street",
                    value
                });
            }
        },
        date_of_birth: {
            get() {
                return this.$store.state.profile.date_of_birth;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "date_of_birth",
                    value
                });
            }
        },
        city: {
            get() {
                return this.$store.state.profile.city;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "city",
                    value
                });
            }
        },
        zip: {
            get() {
                return this.$store.state.profile.zip;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "zip",
                    value: value.toUpperCase()
                });
            }
        },
        firstname: {
            get() {
                return this.$store.state.profile.firstname;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "firstname",
                    value
                });
            }
        },
        phone_number: {
            get() {
                return this.$store.state.profile.phone_number;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "phone_number",
                    value
                });
            }
        },
        lastname: {
            get() {
                return this.$store.state.profile.lastname;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "lastname",
                    value
                });
            }
        },
        gender: {
            get() {
                return this.$store.state.profile.gender;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "gender",
                    value
                });
            }
        },
        email: {
            get() {
                return this.$store.state.profile.email;
            },
            set(value) {
                this.$store.dispatch("updateProfileField", {
                    field: "email",
                    value
                });
            }
        },
        optinCheckboxes: {
            get() {
                return this.$store.state.optinCheckboxes;
            },
            set(value) {
                this.$store.dispatch("updateOptinCheckboxes", {
                    value
                });
            }
        },
    }
};