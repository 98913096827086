<template>
  <div>
    <h3 class="font-weight-bold text-success pt-2 pt-md-3">
      Bedankt voor uw aanvraag!
    </h3>
    <div v-if="lgAnswerOfQuestion(1) == 'koopwoning'" class="p-0 m-0">
      <p>
        Een van onze medewerkers neemt binnenkort contact met u op om uw
        aanvraag voor zonnepanelen op uw dak verder te behandelen.
      </p>

      <div class="aanvulblok p-3 p-md-4 mb-4">
        <p class="p-0 m-0 mb-3 font-weight-bold">
          Om uw aanvraag voor te bereiden vragen wij u onderstaande informatie
          alvast met ons te delen.
        </p>
        <ZonnestroomAanvulling></ZonnestroomAanvulling>
      </div>
      <p class="p-0 m-0">
        Met vriendelijke groet,<br />
        Zonnestroom Collectief
      </p>
    </div>
    <div v-else class="p-0 m-0">
      <p class="p-0 m-0">
        Helaas kunnen wij door de grote interesse in zonnepanelen, aanvragen
        voor huurwoningen niet accepteren. Mocht u toch meer informatie willen
        ontvangen dan kunt u per e-mail contact met ons opnemen via
        info@zonnestroomcollectief.nl<br />
        <br />
        Met vriendelijke groet,<br />
        Zonnestroom Collectief
      </p>
    </div>
  </div>
</template>

<script>
import VuexProfileSetGetters from "@/../../shared/vuex-profile-setgetters.js";
import ZonnestroomAanvulling from "@/../../shared/premium-ads/ZonnestroomCollectief/Aanvulling";
import LgQuestions from "@/../../shared/lg_questions.js";

export default {
  name: "ThankYouPage",
  components: {
    ZonnestroomAanvulling,
  },
  mixins: [VuexProfileSetGetters, LgQuestions],
  computed: {
    baseUrl() {
      return process.env.BASE_URL;
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  font-size: 18px;
}

.aanvulblok {
  border: 1px solid #fcf3a3;
  background-color: #fffdee;

  p {
    font-size: 15px;
  }
}
</style>
