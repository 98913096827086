export default {
  data() {
    return {
      popupIsopen: "",
      alertIsVisible: true
    };
  },
  created() {
    // hot fix for refresh
    document.body.style.overflow = "auto";
  },
  methods: {
    popupOpen(popup_name) {
      // only fire if function has data in it
      if (popup_name) {
        //
        this.popupIsopen = popup_name;

        // disable scroll on website
        document.body.style.overflow = "hidden";
      }
      return false;
    },
    // dismiss all popups
    popupDismiss() {
      // clear popup is open
      this.popupIsopen = "";
      // enable scroll on website
      document.body.style.overflow = "auto";
    }
  }
};