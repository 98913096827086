var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"position-absolute bg-danger p-3 py-md-4 shadow text-white rounded-bottom d-none",staticStyle:{"top":"0","right":"20px"}},[_vm._v(" Installatietijd circa 4 a 6 weken! Wacht niet langer ")]),_vm._m(0),_c('h5',{staticClass:"text-primary font-weight-light p-0 m-0"},[_vm._v(" Installatietijd circa 4 a 6 weken! ")]),_c('form',{staticClass:"pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[(_vm.genderVisible)?_c('div',{staticClass:"form-group col-12 col-md-3"},[_c('label',{attrs:{"for":"gender"}},[_vm._v("Aanhef")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.gender),expression:"gender"}],staticClass:"custom-select",class:{
            'is-invalid': _vm.$v.gender.$error,
            'is-valid': !_vm.$v.gender.$invalid,
          },attrs:{"id":"gender"},on:{"change":[function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.gender=$event.target.multiple ? $$selectedVal : $$selectedVal[0]},function($event){_vm.genderManualEdited = true}]}},[_c('option',{attrs:{"disabled":"","value":""}},[_vm._v("Aanhef")]),_c('option',{attrs:{"value":"male"}},[_vm._v("Dhr.")]),_c('option',{attrs:{"value":"female"}},[_vm._v("Mevr.")])]),(_vm.$v.gender.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Maak een keuze")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-group",class:{
          'col-12 col-md-4': _vm.genderVisible,
          'col-12 col-md-6': !_vm.genderVisible,
        }},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"firstname"}},[_vm._v("Voornaam")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.$v.firstname.$model),expression:"$v.firstname.$model",modifiers:{"trim":true,"lazy":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.firstname.$error,
            'is-valid': !_vm.$v.firstname.$invalid,
          },attrs:{"id":"firstname","type":"text","placeholder":"bv. Pieter","autocomplete":"given-name"},domProps:{"value":(_vm.$v.firstname.$model)},on:{"change":function($event){_vm.$set(_vm.$v.firstname, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.firstname.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw voornaam in")]):_vm._e()]),_c('div',{staticClass:"form-group",class:{
          'col-12 col-md-5': _vm.genderVisible,
          'col-12 col-md-6': !_vm.genderVisible,
        }},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"lastname"}},[_vm._v("Achternaam")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.$v.lastname.$model),expression:"$v.lastname.$model",modifiers:{"trim":true,"lazy":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.lastname.$error,
            'is-valid': !_vm.$v.lastname.$invalid,
          },attrs:{"id":"lastname","type":"text","autocomplete":"family-name","placeholder":"bv. de Jong"},domProps:{"value":(_vm.$v.lastname.$model)},on:{"change":function($event){_vm.$set(_vm.$v.lastname, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.lastname.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw achternaam in")]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-5",class:{ 'col-md-4': _vm.houseNumberExtensionVisible }},[_c('label',{attrs:{"for":"zip"}},[_vm._v("Postcode")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.zip),expression:"zip",modifiers:{"trim":true,"lazy":true}}],staticClass:"form-control answer-height",class:{
            'is-invalid':
              _vm.$v.ziphousenumber.zip.$error || _vm.$v.ziphousenumber.$error,
            'is-valid':
              !_vm.$v.ziphousenumber.zip.$invalid && !_vm.$v.ziphousenumber.$error,
          },attrs:{"id":"zip","type":"text","autocomplete":"postal-code","placeholder":"bv. 2000AB"},domProps:{"value":(_vm.zip)},on:{"blur":[_vm.$v.ziphousenumber.zip.$touch,function($event){return _vm.$forceUpdate()}],"change":function($event){_vm.zip=$event.target.value.trim()}}}),(
            (_vm.$v.ziphousenumber.$error && _vm.rageClickDidTrigger) ||
            (_vm.$v.ziphousenumber.zip.$error && _vm.rageClickDidTrigger)
          )?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw postcode in")]):_vm._e()]),_c('div',{staticClass:"form-group col-7",class:{ 'col-md-4': _vm.houseNumberExtensionVisible }},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"house_number"}},[_vm._v("Huisnummer")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim.lazy",value:(_vm.house_number),expression:"house_number",modifiers:{"trim":true,"lazy":true}}],staticClass:"form-control answer-height",class:{
            'is-invalid':
              _vm.$v.ziphousenumber.house_number.$error ||
              _vm.$v.ziphousenumber.$error,
            'is-valid':
              !_vm.$v.ziphousenumber.house_number.$invalid &&
              !_vm.$v.ziphousenumber.$error,
          },attrs:{"id":"house_number","type":"text","placeholder":"bv. 12"},domProps:{"value":(_vm.house_number)},on:{"blur":[_vm.$v.ziphousenumber.house_number.$touch,function($event){return _vm.$forceUpdate()}],"change":function($event){_vm.house_number=$event.target.value.trim()}}}),(
            (_vm.$v.ziphousenumber.$error && _vm.rageClickDidTrigger) ||
            (_vm.$v.ziphousenumber.house_number.$error && _vm.rageClickDidTrigger)
          )?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw huisnummer in")]):_vm._e()]),(_vm.houseNumberExtensionVisible)?_c('div',{staticClass:"form-group col-12 col-md-4"},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"house_number_ext"}},[_vm._v("Toevoeging")]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.house_number_ext),expression:"house_number_ext"}],staticClass:"custom-select answer-height",class:{
            'is-invalid': _vm.$v.house_number_ext.$error,
            'is-valid':
              !_vm.$v.house_number_ext.$invalid && !_vm.$v.ziphousenumber.$error,
          },attrs:{"id":"house_number_ext"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.house_number_ext=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[(_vm.house_number_possible_ext.indexOf(_vm.house_number_ext) == -1)?_c('option',{attrs:{"selected":""},domProps:{"value":_vm.house_number_ext}},[_vm._v(" Kies ")]):_vm._e(),_vm._l((_vm.house_number_possible_ext),function(ext){return _c('option',{key:ext,domProps:{"value":ext}},[_vm._v(" "+_vm._s(ext === "empty" ? "-" : ext)+" ")])})],2),(_vm.$v.house_number_ext.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw toevoeging in")]):_vm._e()]):_vm._e()]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"phone_number"}},[_vm._v("Telefoonnummer")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.$v.phone_number.$model),expression:"$v.phone_number.$model",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.phone_number.$error,
            'is-valid': !_vm.$v.phone_number.$invalid && _vm.phone_number,
          },attrs:{"id":"phone_number","autocomplete":"tel","type":"tel","placeholder":"bv. 0612345678"},domProps:{"value":(_vm.$v.phone_number.$model)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.$v.phone_number, "$model", $event.target.value.trim())},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.$v.phone_number.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw telefoonnummer in")]):_vm._e()])]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.email.$error,
            'is-valid': !_vm.$v.email.$invalid,
          },attrs:{"id":"email","autocomplete":"email","type":"email","placeholder":"bv. piet@dejong.nl"},domProps:{"value":(_vm.email)},on:{"blur":[_vm.$v.email.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()}}}),(_vm.$v.email.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw e-mail in")]):_vm._e(),_c('mail-check',{staticClass:"text-right mt-2 mb-0",attrs:{"model-name":"email","data":_vm.email}},[_c('span',[_vm._v("Bedoelt u ")])])],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Heeft u een huur- of koopwoning?")]),_c('div',{staticClass:"btn-group w-100",attrs:{"role":"group"}},[_c('a',{staticClass:"btn btn-lg py-2 mr-2 btn-info",class:{
              'btn-danger text-white':
                _vm.lgAnswerOfQuestion(1, true) == 'koopwoning',
            },on:{"click":function($event){$event.preventDefault();return _vm.setLgAnswerOfQuestion(1, 'koopwoning')}}},[_vm._v(" Koopwoning ")]),_c('a',{staticClass:"btn btn-lg btn-info py-2 ml-2",class:{
              'btn-danger text-white':
                _vm.lgAnswerOfQuestion(1, true) == 'huurwoning',
            },on:{"click":function($event){$event.preventDefault();return _vm.setLgAnswerOfQuestion(1, 'huurwoning')}}},[_vm._v(" Huurwoning ")])])])]),_c('AnimatingSubmitButton',{staticClass:"btn btn-lg btn-secondary text-white btn-block mt-2 mt-md-3 py-3 py-md-4",attrs:{"disabled":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING',"loading":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING'}},[(_vm.submitStatus === 'VALIDATING')?_c('span',[_vm._v("Bezig met validatie..")]):(_vm.submitStatus === 'SUBMITTING')?_c('span',[_vm._v("Bezig met versturen..")]):_c('span',[_c('span',{},[_vm._v(" Ja, neem vrijblijvend contact op ")])])])],1),_c('p',{staticClass:"disclaimer text-center m-0 pt-3"},[_vm._v(" Na het versturen van uw gegevens neemt onze adviseur telefonisch contact met u op. ")]),_c('p',{staticClass:"font-weight-bold text-center text-success p-0 m-0 pt-3"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['far', 'lock']}}),_vm._v(" Veilige SSL-verbinding ")],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Investeer extra voordelig "),_c('br',{staticClass:"d-none d-lg-block"}),_vm._v("in zonnepanelen dankzij "),_c('br',{staticClass:"d-none d-lg-block"}),_vm._v("de 0% BTW regeling! ")])
}]

export { render, staticRenderFns }