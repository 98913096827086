import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "../../shared/store";
import Vuelidate from "vuelidate";
import axios from "axios";
import VueAxios from "vue-axios";
import Vuex from "vuex";
import bugsnag from "@bugsnag/js";
import bugsnagVue from "@bugsnag/plugin-vue";
import trackingQueryString from "@/../../shared/tracking-query-string";
import trackingPixels from "@/../../shared/tracking-pixels";
import vueSmoothScroll from "vue2-smooth-scroll";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTimes,
  faCheck,
  faLock,
  faFileAlt,
  faPiggyBank,
  faPhoneAlt,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import axiosRetry from "axios-retry";
import smoothscroll from "smoothscroll-polyfill";

axiosRetry(axios, {
  retries: 4,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: () => true,
});

if (process.env.NODE_ENV == "production") {
  const bugsnagClient = bugsnag({
    apiKey: "c75a4c3ec8ae6d48d6bcbcff2aebf573",
    appVersion: process.env.PACKAGE_VERSION,
  });

  bugsnagClient.use(bugsnagVue, Vue);
}

Vue.use(VueAxios, axios);
Vue.use(Vuelidate);
Vue.use(vueSmoothScroll);
Vue.use(Vuex);
smoothscroll.polyfill();

library.add(faTimes, faCheck, faLock, faFileAlt, faPhoneAlt, faPiggyBank);
Vue.component("font-awesome-icon", FontAwesomeIcon);

require("vue2-animate/dist/vue2-animate.min.css");

Vue.config.productionTip = false;

// load tracking pixels (google/facebook)
trackingPixels(Vue, axios, router, store);

// save subid query parameter to store
router.beforeEach((to, from, next) => {
  let n = trackingQueryString(to, from, next, store);
  return n;
});

function createNewEvent(eventName) {
  var event;
  if (typeof Event === "function") {
    try {
      event = new Event(eventName);
    } catch (e) {
      event = document.createEvent("Event");
      event.initEvent(eventName, true, true);
    }
  } else {
    event = document.createEvent("Event");
    event.initEvent(eventName, true, true);
  }
  return event;
}

new Vue({
  router,
  store,
  mounted: () => document.dispatchEvent(createNewEvent("x-app-rendered")),
  render: (h) => h(App),
}).$mount("#app");
