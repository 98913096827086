<template>
  <div>
    <div
      class="position-absolute bg-danger p-3 py-md-4 shadow text-white rounded-bottom d-none"
      style="top: 0; right: 20px"
    >
      Installatietijd circa 4 a 6 weken! Wacht niet langer
    </div>
    <h3>
      Investeer extra voordelig <br class="d-none d-lg-block" />in zonnepanelen
      dankzij <br class="d-none d-lg-block" />de 0% BTW regeling!
    </h3>
    <h5 class="text-primary font-weight-light p-0 m-0">
      Installatietijd circa 4 a 6 weken!
    </h5>

    <form class="pt-3" @submit.prevent="submitForm">
      <div class="form-row">
        <div v-if="genderVisible" class="form-group col-12 col-md-3">
          <label for="gender">Aanhef</label>
          <select
            id="gender"
            v-model="gender"
            class="custom-select"
            :class="{
              'is-invalid': $v.gender.$error,
              'is-valid': !$v.gender.$invalid,
            }"
            @change="genderManualEdited = true"
          >
            <option disabled value>Aanhef</option>
            <option value="male">Dhr.</option>
            <option value="female">Mevr.</option>
          </select>

          <span
            v-if="$v.gender.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Maak een keuze</span
          >
        </div>

        <div
          class="form-group"
          :class="{
            'col-12 col-md-4': genderVisible,
            'col-12 col-md-6': !genderVisible,
          }"
        >
          <label class="px-2 py-1 m-0" for="firstname">Voornaam</label>

          <input
            id="firstname"
            v-model.trim.lazy="$v.firstname.$model"
            :class="{
              'is-invalid': $v.firstname.$error,
              'is-valid': !$v.firstname.$invalid,
            }"
            class="form-control"
            type="text"
            placeholder="bv. Pieter"
            autocomplete="given-name"
          />
          <span
            v-if="$v.firstname.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw voornaam in</span
          >
        </div>
        <div
          class="form-group"
          :class="{
            'col-12 col-md-5': genderVisible,
            'col-12 col-md-6': !genderVisible,
          }"
        >
          <label class="px-2 py-1 m-0" for="lastname">Achternaam</label>
          <input
            id="lastname"
            v-model.trim.lazy="$v.lastname.$model"
            :class="{
              'is-invalid': $v.lastname.$error,
              'is-valid': !$v.lastname.$invalid,
            }"
            class="form-control"
            type="text"
            autocomplete="family-name"
            placeholder="bv. de Jong"
          />
          <span
            v-if="$v.lastname.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw achternaam in</span
          >
        </div>
      </div>

      <div class="form-row">
        <div
          class="form-group col-5"
          :class="{ 'col-md-4': houseNumberExtensionVisible }"
        >
          <label for="zip">Postcode</label>
          <input
            id="zip"
            v-model.trim.lazy="zip"
            class="form-control answer-height"
            :class="{
              'is-invalid':
                $v.ziphousenumber.zip.$error || $v.ziphousenumber.$error,
              'is-valid':
                !$v.ziphousenumber.zip.$invalid && !$v.ziphousenumber.$error,
            }"
            type="text"
            autocomplete="postal-code"
            placeholder="bv. 2000AB"
            @blur="$v.ziphousenumber.zip.$touch"
          />
          <span
            v-if="
              ($v.ziphousenumber.$error && rageClickDidTrigger) ||
              ($v.ziphousenumber.zip.$error && rageClickDidTrigger)
            "
            class="badge badge-danger"
            >Vul uw postcode in</span
          >
        </div>
        <div
          class="form-group col-7"
          :class="{ 'col-md-4': houseNumberExtensionVisible }"
        >
          <label class="px-2 py-1 m-0" for="house_number">Huisnummer</label>
          <input
            id="house_number"
            v-model.trim.lazy="house_number"
            class="form-control answer-height"
            :class="{
              'is-invalid':
                $v.ziphousenumber.house_number.$error ||
                $v.ziphousenumber.$error,
              'is-valid':
                !$v.ziphousenumber.house_number.$invalid &&
                !$v.ziphousenumber.$error,
            }"
            type="text"
            placeholder="bv. 12"
            @blur="$v.ziphousenumber.house_number.$touch"
          />
          <span
            v-if="
              ($v.ziphousenumber.$error && rageClickDidTrigger) ||
              ($v.ziphousenumber.house_number.$error && rageClickDidTrigger)
            "
            class="badge badge-danger"
            >Vul uw huisnummer in</span
          >
        </div>
        <div
          v-if="houseNumberExtensionVisible"
          class="form-group col-12 col-md-4"
        >
          <label class="px-2 py-1 m-0" for="house_number_ext">Toevoeging</label>
          <select
            id="house_number_ext"
            v-model="house_number_ext"
            class="custom-select answer-height"
            :class="{
              'is-invalid': $v.house_number_ext.$error,
              'is-valid':
                !$v.house_number_ext.$invalid && !$v.ziphousenumber.$error,
            }"
          >
            <option
              v-if="house_number_possible_ext.indexOf(house_number_ext) == -1"
              :value="house_number_ext"
              selected
            >
              Kies
            </option>

            <option
              v-for="ext in house_number_possible_ext"
              :key="ext"
              :value="ext"
            >
              {{ ext === "empty" ? "-" : ext }}
            </option>
          </select>

          <span
            v-if="$v.house_number_ext.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw toevoeging in</span
          >
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label class="px-2 py-1 m-0" for="phone_number">Telefoonnummer</label>
          <input
            id="phone_number"
            v-model.trim="$v.phone_number.$model"
            class="form-control"
            :class="{
              'is-invalid': $v.phone_number.$error,
              'is-valid': !$v.phone_number.$invalid && phone_number,
            }"
            autocomplete="tel"
            type="tel"
            placeholder="bv. 0612345678"
          />
          <span
            v-if="$v.phone_number.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw telefoonnummer in</span
          >
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-12">
          <label class="px-2 py-1 m-0" for="email">E-mail</label>
          <input
            id="email"
            v-model.trim="email"
            class="form-control"
            :class="{
              'is-invalid': $v.email.$error,
              'is-valid': !$v.email.$invalid,
            }"
            autocomplete="email"
            type="email"
            placeholder="bv. piet@dejong.nl"
            @blur="$v.email.$touch"
          />
          <span
            v-if="$v.email.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw e-mail in</span
          >
          <mail-check
            model-name="email"
            :data="email"
            class="text-right mt-2 mb-0"
          >
            <span>Bedoelt u </span>
          </mail-check>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label>Heeft u een huur- of koopwoning?</label>

          <div class="btn-group w-100" role="group">
            <a
              class="btn btn-lg py-2 mr-2 btn-info"
              :class="{
                'btn-danger text-white':
                  lgAnswerOfQuestion(1, true) == 'koopwoning',
              }"
              @click.prevent="setLgAnswerOfQuestion(1, 'koopwoning')"
            >
              Koopwoning
            </a>
            <a
              class="btn btn-lg btn-info py-2 ml-2"
              :class="{
                'btn-danger text-white':
                  lgAnswerOfQuestion(1, true) == 'huurwoning',
              }"
              @click.prevent="setLgAnswerOfQuestion(1, 'huurwoning')"
            >
              Huurwoning
            </a>
          </div>
        </div>
      </div>
      <AnimatingSubmitButton
        class="btn btn-lg btn-secondary text-white btn-block mt-2 mt-md-3 py-3 py-md-4"
        :disabled="
          submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
        "
        :loading="
          submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
        "
      >
        <span v-if="submitStatus === 'VALIDATING'">Bezig met validatie..</span>
        <span v-else-if="submitStatus === 'SUBMITTING'"
          >Bezig met versturen..</span
        >
        <span v-else>
          <span class=""> Ja, neem vrijblijvend contact op </span>
        </span>
      </AnimatingSubmitButton>
    </form>
    <p class="disclaimer text-center m-0 pt-3">
      Na het versturen van uw gegevens neemt onze adviseur telefonisch contact
      met u op.
    </p>
    <p class="font-weight-bold text-center text-success p-0 m-0 pt-3">
      <font-awesome-icon :icon="['far', 'lock']" class="mr-2" /> Veilige
      SSL-verbinding
    </p>
  </div>
</template>
<script>
import VuexProfileSetGetters from "@/../../shared/vuex-profile-setgetters.js";
import ProfileValidators from "@/../../shared/profile-validators.js";
import LeadFormHandler from "@/../../shared/lead-form-handler.js";
import AnimatingSubmitButton from "@/components/AnimatingSubmitButton.vue";
import LgQuestions from "@/../../shared/lg_questions.js";
import { required } from "vuelidate/lib/validators";
import MailCheck from "@/../../shared/components/Mailcheck.vue";

export default {
  name: "Inschrijven",
  components: {
    AnimatingSubmitButton,
    MailCheck,
  },
  mixins: [VuexProfileSetGetters, LeadFormHandler, LgQuestions],
  data() {
    return {
      autoHideGender: true,
      autoCorrectLastname: true,
      autoCorrectFirstname: true,
      rageClickDidTrigger: false,
    };
  },
  validations() {
    return {
      lg_questions: {
        question_1: {
          required,
        },
      },
      gender: ProfileValidators.gender,
      firstname: {
        ...ProfileValidators.firstname,
        firstnameExtendedNL: ProfileValidators.firstnameExtendedNL,
      },

      lastname: {
        ...ProfileValidators.lastname,
        lastnameExtendedNL: ProfileValidators.lastnameExtendedNL,
      },
      email: ProfileValidators.email,
      phone_number: ProfileValidators.NLPhoneNumber,
      ziphousenumber: {
        zip: {
          ...ProfileValidators.NLPostcode,
        },
        house_number: {
          ...ProfileValidators.houseNumber,
        },
        zipcombo: ProfileValidators.NLPostcodeZipCombination,
      },
      house_number_ext: ProfileValidators.NLPostcodeHouseNumberExt,
    };
  },
  mounted() {
    this.lgSetQuestionIsText(1);
    this.setLgAnswerOfQuestion(1, "koopwoning");
  },
  methods: {
    rageClickDetected(event, click_count) {
      this.rageClickDidTrigger = true;
      event.target.scrollIntoView(true);
      if (click_count >= 3) {
        setTimeout(() => {
          alert("Er zijn fouten gevonden in het formulier");
        }, 10);
      }
    },

    getSponsorIds() {
      var sponsors = [19];
      return sponsors;
    },
    getTags() {
      if (this.lgAnswerOfQuestion(1) == "huurwoning") {
        return ["BLOKKADE-ANTWOORD", "ZC_POSTCODE"];
      } else {
        return ["ZC_POSTCODE"];
      }
    },
    beforeSubmit() {},
    submitCompleted() {
      this.$router.push({
        name: "thank-you",
      });
      this.$analytics?.fbq?.event("Lead");

      if (this.lgAnswerOfQuestion(1) == "koopwoning") {
        this.$analytics?.fbq?.event("CompleteRegistration");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/includes";

label {
  font-size: 13px;
}

h3 {
  font-size: 2rem;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 1.3rem;
  }
}
</style>
