import VueFacebookPixel from "@blaaat/vue-facebook-pixel";
import VueGtag from "vue-gtag";

export default (Vue, axios, router, store) => {
    axios({
        method: "get",
        url: process.env.VUE_APP_LG_API_BASE + "api/info/" + process.env.PACKAGE_NAME,
    })
    .then((res) => {
        if (res.data.facebook_id) {
            Vue.use(VueFacebookPixel);
            Vue.analytics.fbq.init(res.data.facebook_id, {});
            if (router.app?.$route && ["35", "3", "6"].indexOf(store.state.traffictype_id) === -1) {
                Vue.analytics.fbq.event("PageView");
            }
            router.beforeEach((to, from, next) => {
                console.log(store.state.traffictype_id);
                if (["35", "3", "6"].indexOf(store.state.traffictype_id) === -1) {
                    Vue.analytics.fbq.event("PageView");
                }
                return next();
            });

        }
        if (res.data.google_id) {
            Vue.use(
                VueGtag,
                {
                config: {
                    id: res.data.google_id,
                },
                bootstrap: !(
                    window.__PRERENDER_INJECTED ||
                    document.__PRERENDER_INJECTED ||
                    window["__PRERENDER_INJECTED"] ||
                    document["__PRERENDER_INJECTED"]
                ),
                },
                router
            );
        }
    })
    .catch((e) => {});
};