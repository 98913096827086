<template>
  <div ref="adcontainer">
    <div v-if="!didOptin">
      <form class="form-horizontal text-left" @submit.prevent="submitForm">
        <div class="form-row">
          <div class="form-group col-12">
            <label>Hoeveel kWh verbruikt u per jaar (schatting)?</label>
            <select :key="lgKeyForAnswersOfQuestion(31)" class="custom-select" :class="{
              'is-invalid': $v.lg_questions.question_31.$error,
              'is-valid': !$v.lg_questions.question_31.$invalid,
            }" :value="lgAnswerOfQuestion(31)" @change="(e) => setLgAnswerOfQuestion(31, e.target.value)">
              <option value disabled>Maak een keuze</option>
              <option value="0">Ik kan geen schatting maken</option>
              <option value="2300">2.300 kWh (1 persoon)</option>
              <option value="3400">3.400 kWh (2 personen)</option>
              <option value="4100">4.100 kWh (3 personen)</option>
              <option value="4600">4.600 kWh (4 personen)</option>
              <option value="5300">5.300 kWh (5 personen)</option>
              <option value="5400">5.400 kWh (6 personen)</option>
              <option value="5401">Meer dan 5.400 kWh</option>
            </select>

            <span v-if="$v.lg_questions.question_31.$error && rageClickDidTrigger" class="badge badge-danger">Maak een
              keuze</span>
          </div>
          <div class="form-group col-12">
            <label>Hoeveel zonnepanelen wilt u laten plaatsen?</label>
            <select :key="lgKeyForAnswersOfQuestion(34)" class="custom-select" :class="{
              'is-invalid': $v.lg_questions.question_34.$error,
              'is-valid': !$v.lg_questions.question_34.$invalid,
            }" :value="lgAnswerOfQuestion(34)" @change="(e) => setLgAnswerOfQuestion(34, e.target.value)">
              <option value disabled>Maak een keuze</option>
              <option value="onbekend">Nog niet bekend</option>
              <option value="1-10-panelen">1 tot 10 panelen</option>
              <option value="11-20-panelen">11 tot 20 panelen</option>
              <option value="20plus-panelen">Meer dan 20 panelen</option>
            </select>

            <span v-if="$v.lg_questions.question_34.$error && rageClickDidTrigger" class="badge badge-danger">Maak een
              keuze</span>
          </div>
          <div class="form-group col-12">
            <label>Wanneer wilt u de zonnepanelen laten plaatsen?</label>
            <select :key="lgKeyForAnswersOfQuestion(37)" class="custom-select" :class="{
              'is-invalid': $v.lg_questions.question_37.$error,
              'is-valid': !$v.lg_questions.question_37.$invalid,
            }" :value="lgAnswerOfQuestion(37)" @change="(e) => setLgAnswerOfQuestion(37, e.target.value)">
              <option value disabled>Maak een keuze</option>
              <option value="in-overleg">In overleg</option>
              <option value="zo-snel-mogelijk">Zo snel mogelijk</option>
              <option value="binnen-3-maanden">Binnen 3 maanden</option>
              <option value="binnen-3-tot-6-maanden">
                Binnen 3 tot 6 maanden
              </option>
            </select>

            <span v-if="$v.lg_questions.question_37.$error && rageClickDidTrigger" class="badge badge-danger">Maak een
              keuze</span>
          </div>
        </div>

        <AnimatingSubmitButton class="btn btn-lg btn-success btn-block mt-3 py-3 px-0" :disabled="
          submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
        " :loading="
  submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
">
          <span v-if="submitStatus === 'VALIDATING'">Bezig met validatie..</span>
          <span v-else-if="submitStatus === 'SUBMITTING'">Bezig met versturen..</span>
          <span v-else>
            <h2 class="p-0 m-0 font-weight-bold">Aanvraag aanvullen &raquo;</h2>
          </span>
        </AnimatingSubmitButton>
      </form>
    </div>
    <div class="bedankt p-3 p-md-4" v-else>
      <h4 class="p-0 m-0 text-success">Extra gegevens ontvangen!</h4>
      <p class="p-0 m-0">
        Bedankt voor het invullen van de extra gegevens. Hiermee kunnen wij uw
        aanvraag direct beter voorbereiden en u van een zo passend mogelijk
        voorstel voorzien.
      </p>
    </div>
  </div>
</template>

<script>
import VuexProfileSetGetters from "@/../../shared/vuex-profile-setgetters.js";
import LeadFormHandler from "@/../../shared/lead-form-handler.js";
import LgQuestions from "@/../../shared/lg_questions.js";
import AnimatingSubmitButton from "@/components/AnimatingSubmitButton.vue";
import { required } from "vuelidate/lib/validators";
export default {
  name: "Aanvullen",
  components: {
    AnimatingSubmitButton,
  },
  mixins: [VuexProfileSetGetters, LeadFormHandler, LgQuestions],
  data() {
    return {
      didOptin: false,
      rageClickDidTrigger: false,
    };
  },
  mounted() {
    this.lgSetQuestionIsText(31);
    this.lgSetQuestionIsText(34);
    this.lgSetQuestionIsText(37);
  },
  validations() {
    return {
      lg_questions: {
        question_31: {
          required,
        },
        question_37: {
          required,
        },
        question_34: {
          required,
        },
      },
    };
  },

  methods: {
    rageClickDetected(event, click_count) {
      this.rageClickDidTrigger = true;
      event.target.scrollIntoView(true);
      if (click_count >= 3) {
        setTimeout(() => {
          alert("Er zijn fouten gevonden in het formulier");
        }, 10);
      }
    },
    getSponsorIds() {
      var sponsors = [19]; // standaard uitleveren aan sponsor 1319
      return sponsors;
    },
    beforeSubmit() { },
    submitCompleted() {
      this.didOptin = true;
    },
  },
};
</script>

<style lang="scss" scoped>
label {
  font-size: 13px;
}

h2 {
  font-size: 1.3rem;
}

.bedankt {
  background-color: #eeffee;
  border: 1px solid #9eec9e;
}
</style>