
export default {
    data() {
        return {
            lg_question_data: {},
            autoSubmitLgTextAnswers: true,
            autoSubmitLgNonTextAnswers: true,
        };
    },
    computed: {
        lg_question_answers() {
            let l = {};
            for (let key in this.lg_question_data) {
                l[key] = this.lg_question_data[key].answers;
            }
            return l;
        },
        lg_question_all_positive_answers() {
            let l = [];
            for (let key in this.lg_question_data) {
                l = l.concat(this.lg_question_data[key].answers.filter(x => x.is_positive == 1));
            }
            return l;
        },
        lg_question_all_positive_answer_ids() {
            return this.lg_question_all_positive_answers.map(x => x.id);
        },
        lg_questions() {
            let l = {};
            for (let x of this.$store.getters.allLGQuestionIds) {
                let k = 'question_' + x;
                l[k] = this.lgAnswerOfQuestion(x);
            }
            return l;
        },
    },
    methods: {
        lgGetAnswerIds() {
            let l = [];

            for (let x of this.all_leadgen_answers()) {
                if (this.$store.getters.allLGTextQuestionIds.indexOf(x.question_id) === -1) {
                    l.push(x.value);
                }
            }

            l = l.filter(l => l != '');

            return l;
        },
        getAnswerIds() {
            if (this.autoSubmitLgNonTextAnswers) {
                return this.lgGetAnswerIds();
            }
            return [];
        },
        lgGetTextAnswers() {
            let l = {};
            for (let x of this.$store.getters.allLGTextQuestionIds) {
                if (this.lgAnswerOfQuestion(x)) {
                    l[x] = this.lgAnswerOfQuestion(x);
                }
            }
            return l;
        },
        getTextAnswers() {
            if (this.autoSubmitLgTextAnswers) {
                return this.lgGetTextAnswers();
            }
            return {};
        },
        lgLoadQuestionFromAPI(question_id) {
            return this.axios({
                method: 'get',
                url: process.env.VUE_APP_LG_API_BASE + "api/questions/" + question_id,
                auth: {
                    username: this.$store.getters.myslgUsername,
                    password: this.$store.getters.myslgPassword
                }
            })
                .then(res => {
                    let k = 'question_' + res.data.id;
                    this.$set(this.lg_question_data, k, res.data);
                    return true;
                })
                .catch(e => {
                    console.log('Error loading question')
                    return false;
                });

        },
        lgKeyForAnswersOfQuestion(question_id) {
            let k = 'question_' + question_id;

            if (this.lg_question_data.hasOwnProperty(k)) {
                return k + 'ok';
            } else {
                return k + 'loading';
            }
        },
        lgQuestionHasValidator(question_id) {
            let k = 'question_' + question_id;
            return this.$v.lg_questions[k] !== undefined;
        },
        lgQuestionAddId(question_id) {
            if (this.$store.getters.allLGQuestionIds.indexOf(question_id) === -1) {
                this.$store.dispatch("lgQuestionAddId",
                    question_id
                );
            }
        },
        lgSetQuestionIsText(question_id) {
            if (this.$store.getters.allLGTextQuestionIds.indexOf(question_id) === -1) {
                this.$store.dispatch("lgSetQuestionIsText",
                    question_id
                );
            }
        },
        lgQuestionHasPositiveAnswer(question_id) {
            var answer = this.lgAnswerOfQuestion(question_id);
            if (!answer) {
                return false;
            }
            return this.lg_question_all_positive_answer_ids.includes(
                parseInt(answer)
            );
        },
        lgAnswerOfQuestion(question_id, is_text_answer) {
            if (is_text_answer) {
                this.lgSetQuestionIsText(question_id);
            }
            this.lgQuestionAddId(question_id);
            let p = this.$store.getters.answerOfLgQuestion(question_id);
            if (p) {
                return p.value;
            }
            return '';
        },
        all_leadgen_answers() {
            let p = this.$store.getters.allLGAnswers;
            if (p) {
                return p;
            }
            return [];

        },
        lgMultipleAnswerOfQuestion(question_id) {
            this.lgQuestionAddId(question_id);
            let p = this.$store.getters.multipleAnswerOfLgQuestion(question_id);
            if (p) {
                return p.map(x => x.value);
            }
            return [];
        },
        setLgAnswerOfQuestion(question_id, value) {
            this.$store.dispatch("replaceLgAnswerOfQuestion", {
                question_id,
                value
            });
        },
        toggleLgAnswerOfQuestion(question_id, value) {
            if (this.lgMultipleAnswerOfQuestion(question_id).indexOf(value) === -1) {
                this.enableLgAnswerOfQuestion(question_id, value);
            } else {
                this.disableLgAnswerOfQuestion(question_id, value);

            }
        },
        enableLgAnswerOfQuestion(question_id, value) {
            this.$store.dispatch("enableLgAnswerOfQuestion", {
                question_id,
                value
            });
        },
        disableLgAnswerOfQuestion(question_id, value) {
            this.$store.dispatch("disableLgAnswerOfQuestion", {
                question_id,
                value
            });
        }

    }
}