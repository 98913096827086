export default {
    data() {
        return {
            submitStatus: null,
            genderUnknown: false,
            genderManualEdited: false,
            num_active_clicks: 0,
            rage_click_timeout: 1300,
            preventFormSubmit: false,
        }
    },
    computed: {
        genderVisible() {
            return !(
                this.autoHideGender &&
                !this.genderUnknown &&
                !this.genderManualEdited
            );
        },
        houseNumberExtensionVisible() {
            return this.$store.state.profile.house_number_possible_ext.length > 1;
        }
    },
    methods: {
        getSponsorIds() {
            return [];
        },
        getAnswerIds() {
            return [];
        },
        getTextAnswers() {
            return {};
        },
        getTags() {
            return [];
        },
        beforeSubmit() {
            return;
        },
        getWebhookTriggers() {
            return {};
        },
        rageClickDetected(event, click_count) {
            event.target.scrollIntoView(true);
            setTimeout(() => {
                alert('Er zijn fouten gevonden in het formulier');
            }, 10);
        },
        validationCompleted() {
            return;
        },
        _validations() {
            return new Promise(resolve => {
                if (this.$v.$error || !this.$v.$pending) {
                    return resolve();
                }
                let poll = setInterval(() => {
                    if (!this.$v.$pending) {
                        clearInterval(poll);
                        resolve();
                    }
                }, 200);
            });
        },
        async isValid() {
            this.$v.$reset();
            this.$v.$touch();
            await this._validations();
            return Promise.resolve(!this.$v.$error);
        },
        async validateForm(e) {
            this.$v.$reset()
            this.$v.$touch();
            if (this.$v.$pending) {
                this.submitStatus = "VALIDATING";
                await this.$nextTick()
            }
            const isValid = await this.isValid();
            if (this.$v.$invalid) {
                this.num_active_clicks += 1;
                setTimeout(() => {
                    this.num_active_clicks -= 1;
                }, this.rage_click_timeout);

                if (this.num_active_clicks >= 2) {
                    this.rageClickDetected(e, this.num_active_clicks);
                }
                this.submitStatus = "ERROR";
            } else {
                this.validationCompleted();
            }
        },
        async submitForm(e) {
            if (typeof this.preventFormSubmit !== 'undefined' && this.preventFormSubmit) {
                e.preventDefault();
                return false;
            }

            this.$v.$reset()
            this.$v.$touch();
            if (this.$v.$pending) {
                this.submitStatus = "VALIDATING";
                await this.$nextTick()
            }
            const isValid = await this.isValid();
            if (this.$v.$invalid) {
                this.num_active_clicks += 1;
                setTimeout(() => {
                    this.num_active_clicks -= 1;
                }, this.rage_click_timeout);

                if (this.num_active_clicks >= 2) {
                    this.rageClickDetected(e, this.num_active_clicks);
                }
                this.submitStatus = "ERROR";
            } else {
                // after form validation
                this.submitStatus = "SUBMITTING";
                this.beforeSubmit();

                var hasProfile = this.$store.state.profile.id && this.$store.state.profile.secret;

                var data = {
                    language: (this.locale || process.env.VUE_APP_LG_LOCALE),
                    package_name: process.env.PACKAGE_NAME,
                    site_subid: this.$store.state.tracking_subid,
                    publisher_id: this.$store.state.publisher_id,
                    site_custom_url: this.$store.state.entry_url || window.location.href,
                    cosponsor_optins: this.getSponsorIds(),
                    answers: this.getAnswerIds(),
                    webhook_triggers: this.getWebhookTriggers(),
                    text_answers: this.getTextAnswers(),
                    do_not_sell: (process.env.NODE_ENV == 'development' ? 'yes' : 'no'),
                    add_tags: this.getTags()
                };

                var url = process.env.VUE_APP_LG_API_BASE + "api/leads";

                if (hasProfile) {
                    url += '/' + this.$store.state.profile.id;
                    data.lead_secret = this.$store.state.profile.secret;
                }
                // fill request with available profile data
                if (this.$store.state.referrer) {
                    data.http_referer = this.$store.state.referrer;
                }
                if (this.$store.state.profile.firstname) {
                    data.firstname = this.$store.state.profile.firstname;
                }
                if (this.$store.state.profile.lastname) {
                    data.lastname = this.$store.state.profile.lastname;
                }
                if (this.$store.state.profile.email) {
                    data.email = this.$store.state.profile.email;
                }
                if (this.$store.state.profile.city) {
                    data.city = this.$store.state.profile.city;
                }
                if (this.$store.state.profile.street) {
                    data.street = this.$store.state.profile.street;
                }
                if (this.$store.state.profile.house_number) {
                    data.house_number = this.$store.getters.house_number_full;
                }
                if (this.$store.state.profile.zip) {
                    data.zip = this.$store.state.profile.zip;
                }
                if (this.$store.state.profile.phone_number) {
                    data.phone_number = this.$store.state.profile.phone_number;
                }
                if (this.$store.state.profile.gender) {
                    data.gender = this.$store.state.profile.gender;
                }
                if (this.$store.state.profile.date_of_birth) {
                    data.date_of_birth = this.$store.state.profile.date_of_birth;
                }

                this.axios({
                    method: hasProfile ? 'put' : 'post',
                    url,
                    data,
                    auth: {
                        username: this.$store.getters.myslgUsername,
                        password: this.$store.getters.myslgPassword
                    }
                })
                    .then(res => {
                        if (res.status == 201 || res.status == 200) {
                            this.submitStatus = "COMPLETED";
                            if (res.data.profile.id) {
                                this.$store.dispatch("updateProfileField", {
                                    field: "id",
                                    value: res.data.profile.id
                                });
                            }
                            if (res.data.secret) {
                                this.$store.dispatch("updateProfileField", {
                                    field: "secret",
                                    value: res.data.secret
                                });
                            }

                            this.submitCompleted();
                        } else {
                            this.submitStatus = "FAILED";
                            return false;
                        }
                    })
                    .catch(error => {
                        if (error.response && error.response.status) {
                            alert('HTTP ' + error.response.status + ': ' + (error.response.data.error ? error.response.data.error : ''));
                        } else {
                            alert('Error occurred, please try again');
                        }
                        this.submitStatus = "FAILED";
                        return false;
                    });
            }
        }
    }
};