var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"position-absolute bg-danger p-3 py-md-4 shadow text-white rounded-bottom d-none",staticStyle:{"top":"0","right":"20px"}},[_vm._v(" Bedankt voor je offerte aanvraag! "+_vm._s(_vm.$store.state)+" ")]),_vm._m(0),_c('h5',{staticClass:"text-primary font-weight-light p-0 m-0"},[_vm._v(" Meld je aan voor onze nieuwsbrief ")]),_c('form',{staticClass:"pt-3",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',{staticClass:"px-2 py-1 m-0",attrs:{"for":"email"}},[_vm._v("E-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],staticClass:"form-control",class:{
            'is-invalid': _vm.$v.email.$error,
            'is-valid': !_vm.$v.email.$invalid,
          },attrs:{"id":"email","autocomplete":"email","type":"email","placeholder":"bv. piet@dejong.nl"},domProps:{"value":(_vm.email)},on:{"blur":[_vm.$v.email.$touch,function($event){return _vm.$forceUpdate()}],"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()}}}),(_vm.$v.email.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Vul uw e-mail in")]):_vm._e(),_c('mail-check',{staticClass:"text-right mt-2 mb-0",attrs:{"model-name":"email","data":_vm.email}},[_c('span',[_vm._v("Bedoelt u ")])])],1)]),_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Heeft u een huur- of koopwoning?")]),_c('div',{staticClass:"btn-group w-100",attrs:{"role":"group"}},[_c('a',{staticClass:"btn btn-lg py-2 mr-2 btn-info",class:{
              'btn-danger text-white':
                _vm.lgAnswerOfQuestion(1, true) == 'koopwoning',
            },on:{"click":function($event){$event.preventDefault();return _vm.setLgAnswerOfQuestion(1, 'koopwoning')}}},[_vm._v(" Koopwoning ")]),_c('a',{staticClass:"btn btn-lg btn-info py-2 ml-2",class:{
              'btn-danger text-white':
                _vm.lgAnswerOfQuestion(1, true) == 'huurwoning',
            },on:{"click":function($event){$event.preventDefault();return _vm.setLgAnswerOfQuestion(1, 'huurwoning')}}},[_vm._v(" Huurwoning ")])])]),_c('div',{staticClass:"form-group col-12 p-2 pt-3 m-0"},[_c('div',{staticClass:"form-check"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.optinCheckboxes),expression:"optinCheckboxes"}],staticClass:"form-check-input",class:{
              'is-invalid':
                _vm.$v.optinCheckboxes.$dirty &&
                !_vm.$v.optinCheckboxes.TermsAreRequired,
              'is-valid':
                _vm.$v.optinCheckboxes.$dirty &&
                _vm.$v.optinCheckboxes.TermsAreRequired,
            },attrs:{"id":"check1","value":"algemene-voorwaarden","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.optinCheckboxes)?_vm._i(_vm.optinCheckboxes,"algemene-voorwaarden")>-1:(_vm.optinCheckboxes)},on:{"change":[function($event){var $$a=_vm.optinCheckboxes,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v="algemene-voorwaarden",$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.optinCheckboxes=$$a.concat([$$v]))}else{$$i>-1&&(_vm.optinCheckboxes=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.optinCheckboxes=$$c}},function($event){return _vm.$v.optinCheckboxes.$touch()}]}}),_c('label',{staticClass:"form-check-label ml-1",attrs:{"for":"check1"}},[_vm._v("Ja schrijf mij in voor de e-mail nieuwsbrief, hou mij op de hoogte van het laatste zonnepanelen nieuws, onze aanbiedingen en aanbiedingen van partners.")]),(!_vm.$v.optinCheckboxes.TermsAreRequired && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Geef akkoord op de voorwaarden.")]):_vm._e()])])]),_c('AnimatingSubmitButton',{staticClass:"btn btn-lg btn-secondary text-white btn-block mt-2 mt-md-3 py-3 py-md-4",attrs:{"disabled":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING',"loading":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING'}},[(_vm.submitStatus === 'VALIDATING')?_c('span',[_vm._v("Bezig met validatie..")]):(_vm.submitStatus === 'SUBMITTING')?_c('span',[_vm._v("Bezig met versturen..")]):_c('span',[_c('span',{},[_vm._v(" Meld mij aan voor de nieuwsbrief ")])])])],1),_c('p',{staticClass:"font-weight-bold text-center text-success p-0 m-0 pt-3"},[_c('font-awesome-icon',{staticClass:"mr-2",attrs:{"icon":['far', 'lock']}}),_vm._v(" Veilige SSL-verbinding ")],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h3',[_vm._v(" Blijf op de hoogte van al "),_c('br',{staticClass:"d-none d-lg-block"}),_vm._v("onze aanbiedingen en zonnepanelen nieuws! ")])
}]

export { render, staticRenderFns }