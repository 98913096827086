import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    locale: false,
    splittest_version: 1,
    network_id: null,
    tracking_subid: '',
    publisher_id: '',
    fbclid: null,
    entry_url: false,
    referrer: false,
    traffictype_id: '',
    profile: {
      id: '',
      secret: '',
      firstname: '',
      email: '',
      lastname: '',
      gender: '',
      street: '',
      house_number: '',
      house_number_ext: '',
      house_number_possible_ext: [],
      house_number_possible_pand_info: [],
      house_number_possible_pand_info_be: {},
      city: '',
      zip: '',
      phone_number: '',
      date_of_birth: '',
    },
    answers: [],
    lg_answers: [],
    lg_text_question_ids: [],
    lg_question_ids: [],
    optinCheckboxes: [],
  },
  getters: {
    myslgUsername: (state) => state.network_id ?? process.env.VUE_APP_LG_SITE_ID,
    myslgPassword: (state) => state.network_id === null ? process.env.VUE_APP_LG_API_KEY : window.btoa(state.network_id + 'p'),
    house_number_full: (state) => {
      if (state.profile.house_number_ext && state.profile.house_number_ext == '') {
        return state.profile.house_number;
      }
      else if (state.profile.house_number_ext && state.profile.house_number_ext != 'empty') {
        return state.profile.house_number + ' ' + state.profile.house_number_ext;
      }
      return state.profile.house_number;
    },
    answersOfQuestion: (state) => (question_ref) => {
      return state.answers.filter(q => q.question_ref == question_ref);
    },
    answerOfLgQuestion: (state) => (question_id) => {
      return state.lg_answers.find(q => q.question_id == question_id);
    },
    multipleAnswerOfLgQuestion: (state) => (question_id) => {
      return state.lg_answers.filter(q => q.question_id == question_id);
    },
    allLGAnswers: (state) => {
      return state.lg_answers;
    },
    allLGQuestionIds: (state) => {
      return state.lg_question_ids;
    },
    allLGTextQuestionIds: (state) => {
      return state.lg_text_question_ids;
    }
  },
  mutations: {
    ADD_LG_ANSWER(state, p) {
      state.lg_answers.push(p);
    },
    DELETE_LG_ANSWERS(state, question_id) {
      state.lg_answers = state.lg_answers.filter(q => q.question_id != question_id);
    },
    DELETE_LG_ANSWER(state, p) {
      state.lg_answers = state.lg_answers.filter(q => p.value != q.value);
    },
    ADD_ANSWER(state, p) {
      p.answer.question_ref = p.question.ref;
      state.answers.push(p.answer);
    },
    DELETE_ANSWERS(state, question) {
      state.answers = state.answers.filter(q => q.question_ref != question.ref);
    },
    SET_PROFILE_FIELD(state, p) {
      state.profile[p.field] = p.value;
    },
    SET_CHECKBOXES(state, p) {
      state.optinCheckboxes = p.value;
    },
    SET_SUBID(state, p) {
      state.tracking_subid = p.value;
    },
    SET_TTID(state, p) {
      state.traffictype_id = p.value;
    },
    SET_PUBID(state, p) {
      state.publisher_id = p.value;
    },
    SET_FBCLID(state, p) {
      state.fbclid = p.value;
    },
    SET_NETWORKID(state, p) {
      state.network_id = p.value;
    },

    SET_SPLITTEST_VERSION(state, p) {
      state.splittest_version = p.value;
    },
    SET_ENTRYURL(state, p) {
      state.entry_url = p.value;
    },
    SET_REFERRER(state, p) {
      state.referrer = p.value;
    },
    ADD_LG_QUESTION_ID(state, p) {
      state.lg_question_ids.push(p);
    },
    ADD_LG_TEXT_QUESTION_ID(state, p) {
      state.lg_text_question_ids.push(p);
    },
    SET_LOCALE(state, p) {
      state.locale = p;
    },

  },
  actions: {
    replaceLgAnswerOfQuestion({ commit }, p) {
      commit('DELETE_LG_ANSWERS', p.question_id);
      commit('ADD_LG_ANSWER', p);
    },
    enableLgAnswerOfQuestion({ commit }, p) {
      commit('DELETE_LG_ANSWER', p);
      commit('ADD_LG_ANSWER', p);
    },
    disableLgAnswerOfQuestion({ commit }, p) {
      commit('DELETE_LG_ANSWER', p);
    },
    replaceAnswerOfQuestion({ commit }, p) {
      commit('DELETE_ANSWERS', p.question);
      commit('ADD_ANSWER', p);
    },
    updateProfileField({ commit }, p) {
      commit('SET_PROFILE_FIELD', p);
    },
    updateOptinCheckboxes({ commit }, p) {
      commit('SET_CHECKBOXES', p);
    },
    setTrackingSubid({ commit }, p) {
      commit('SET_SUBID', p);
    },
    setTraffictypeId({ commit }, p) {
      commit('SET_TTID', p);
    },
    setPublisherId({ commit }, p) {
      commit('SET_PUBID', p);
    },
    setNetworkId({ commit }, p) {
      commit('SET_NETWORKID', p);
    },

    setFacebookClickId({ commit }, p) {
      commit('SET_FBCLID', p);
    },

    setEntryURL({ commit }, p) {
      commit('SET_ENTRYURL', p);
    },
    setReferrer({ commit }, p) {
      commit('SET_REFERRER', p);
    },
    setSplittestVersion({ commit }, p) {
      commit('SET_SPLITTEST_VERSION', p);
    },
    lgQuestionAddId({ commit }, p) {
      commit('ADD_LG_QUESTION_ID', p);
    },
    lgSetQuestionIsText({ commit }, p) {
      commit('ADD_LG_TEXT_QUESTION_ID', p);
    },
    setLocale({ commit }, p) {
      commit('SET_LOCALE', p);
    }

  }
});
