var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"adcontainer"},[(!_vm.didOptin)?_c('div',[_c('form',{staticClass:"form-horizontal text-left",on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('div',{staticClass:"form-row"},[_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Hoeveel kWh verbruikt u per jaar (schatting)?")]),_c('select',{key:_vm.lgKeyForAnswersOfQuestion(31),staticClass:"custom-select",class:{
              'is-invalid': _vm.$v.lg_questions.question_31.$error,
              'is-valid': !_vm.$v.lg_questions.question_31.$invalid,
            },domProps:{"value":_vm.lgAnswerOfQuestion(31)},on:{"change":(e) => _vm.setLgAnswerOfQuestion(31, e.target.value)}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Maak een keuze")]),_c('option',{attrs:{"value":"0"}},[_vm._v("Ik kan geen schatting maken")]),_c('option',{attrs:{"value":"2300"}},[_vm._v("2.300 kWh (1 persoon)")]),_c('option',{attrs:{"value":"3400"}},[_vm._v("3.400 kWh (2 personen)")]),_c('option',{attrs:{"value":"4100"}},[_vm._v("4.100 kWh (3 personen)")]),_c('option',{attrs:{"value":"4600"}},[_vm._v("4.600 kWh (4 personen)")]),_c('option',{attrs:{"value":"5300"}},[_vm._v("5.300 kWh (5 personen)")]),_c('option',{attrs:{"value":"5400"}},[_vm._v("5.400 kWh (6 personen)")]),_c('option',{attrs:{"value":"5401"}},[_vm._v("Meer dan 5.400 kWh")])]),(_vm.$v.lg_questions.question_31.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Maak een keuze")]):_vm._e()]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Hoeveel zonnepanelen wilt u laten plaatsen?")]),_c('select',{key:_vm.lgKeyForAnswersOfQuestion(34),staticClass:"custom-select",class:{
              'is-invalid': _vm.$v.lg_questions.question_34.$error,
              'is-valid': !_vm.$v.lg_questions.question_34.$invalid,
            },domProps:{"value":_vm.lgAnswerOfQuestion(34)},on:{"change":(e) => _vm.setLgAnswerOfQuestion(34, e.target.value)}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Maak een keuze")]),_c('option',{attrs:{"value":"onbekend"}},[_vm._v("Nog niet bekend")]),_c('option',{attrs:{"value":"1-10-panelen"}},[_vm._v("1 tot 10 panelen")]),_c('option',{attrs:{"value":"11-20-panelen"}},[_vm._v("11 tot 20 panelen")]),_c('option',{attrs:{"value":"20plus-panelen"}},[_vm._v("Meer dan 20 panelen")])]),(_vm.$v.lg_questions.question_34.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Maak een keuze")]):_vm._e()]),_c('div',{staticClass:"form-group col-12"},[_c('label',[_vm._v("Wanneer wilt u de zonnepanelen laten plaatsen?")]),_c('select',{key:_vm.lgKeyForAnswersOfQuestion(37),staticClass:"custom-select",class:{
              'is-invalid': _vm.$v.lg_questions.question_37.$error,
              'is-valid': !_vm.$v.lg_questions.question_37.$invalid,
            },domProps:{"value":_vm.lgAnswerOfQuestion(37)},on:{"change":(e) => _vm.setLgAnswerOfQuestion(37, e.target.value)}},[_c('option',{attrs:{"value":"","disabled":""}},[_vm._v("Maak een keuze")]),_c('option',{attrs:{"value":"in-overleg"}},[_vm._v("In overleg")]),_c('option',{attrs:{"value":"zo-snel-mogelijk"}},[_vm._v("Zo snel mogelijk")]),_c('option',{attrs:{"value":"binnen-3-maanden"}},[_vm._v("Binnen 3 maanden")]),_c('option',{attrs:{"value":"binnen-3-tot-6-maanden"}},[_vm._v(" Binnen 3 tot 6 maanden ")])]),(_vm.$v.lg_questions.question_37.$error && _vm.rageClickDidTrigger)?_c('span',{staticClass:"badge badge-danger"},[_vm._v("Maak een keuze")]):_vm._e()])]),_c('AnimatingSubmitButton',{staticClass:"btn btn-lg btn-success btn-block mt-3 py-3 px-0",attrs:{"disabled":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING',"loading":_vm.submitStatus === 'SUBMITTING' || _vm.submitStatus === 'VALIDATING'}},[(_vm.submitStatus === 'VALIDATING')?_c('span',[_vm._v("Bezig met validatie..")]):(_vm.submitStatus === 'SUBMITTING')?_c('span',[_vm._v("Bezig met versturen..")]):_c('span',[_c('h2',{staticClass:"p-0 m-0 font-weight-bold"},[_vm._v("Aanvraag aanvullen »")])])])],1)]):_c('div',{staticClass:"bedankt p-3 p-md-4"},[_c('h4',{staticClass:"p-0 m-0 text-success"},[_vm._v("Extra gegevens ontvangen!")]),_c('p',{staticClass:"p-0 m-0"},[_vm._v(" Bedankt voor het invullen van de extra gegevens. Hiermee kunnen wij uw aanvraag direct beter voorbereiden en u van een zo passend mogelijk voorstel voorzien. ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }