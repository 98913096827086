<!-- @format -->

<template>
  <div>
    <div
      class="position-absolute bg-danger p-3 py-md-4 shadow text-white rounded-bottom d-none"
      style="top: 0; right: 20px"
    >
      Bedankt voor je offerte aanvraag! {{ $store.state }}
    </div>
    <h3>
      Blijf op de hoogte van al <br class="d-none d-lg-block" />onze
      aanbiedingen en zonnepanelen nieuws!
    </h3>
    <h5 class="text-primary font-weight-light p-0 m-0">
      Meld je aan voor onze nieuwsbrief
    </h5>

    <form class="pt-3" @submit.prevent="submitForm">
      <div class="form-row">
        <div class="form-group col-12">
          <label class="px-2 py-1 m-0" for="email">E-mail</label>
          <input
            id="email"
            v-model.trim="email"
            class="form-control"
            :class="{
              'is-invalid': $v.email.$error,
              'is-valid': !$v.email.$invalid,
            }"
            autocomplete="email"
            type="email"
            placeholder="bv. piet@dejong.nl"
            @blur="$v.email.$touch"
          />
          <span
            v-if="$v.email.$error && rageClickDidTrigger"
            class="badge badge-danger"
            >Vul uw e-mail in</span
          >
          <mail-check
            model-name="email"
            :data="email"
            class="text-right mt-2 mb-0"
          >
            <span>Bedoelt u </span>
          </mail-check>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-12">
          <label>Heeft u een huur- of koopwoning?</label>

          <div class="btn-group w-100" role="group">
            <a
              class="btn btn-lg py-2 mr-2 btn-info"
              :class="{
                'btn-danger text-white':
                  lgAnswerOfQuestion(1, true) == 'koopwoning',
              }"
              @click.prevent="setLgAnswerOfQuestion(1, 'koopwoning')"
            >
              Koopwoning
            </a>
            <a
              class="btn btn-lg btn-info py-2 ml-2"
              :class="{
                'btn-danger text-white':
                  lgAnswerOfQuestion(1, true) == 'huurwoning',
              }"
              @click.prevent="setLgAnswerOfQuestion(1, 'huurwoning')"
            >
              Huurwoning
            </a>
          </div>
        </div>
        <div class="form-group col-12 p-2 pt-3 m-0">
          <div class="form-check">
            <input
              id="check1"
              v-model="optinCheckboxes"
              class="form-check-input"
              :class="{
                'is-invalid':
                  $v.optinCheckboxes.$dirty &&
                  !$v.optinCheckboxes.TermsAreRequired,
                'is-valid':
                  $v.optinCheckboxes.$dirty &&
                  $v.optinCheckboxes.TermsAreRequired,
              }"
              value="algemene-voorwaarden"
              type="checkbox"
              @change="$v.optinCheckboxes.$touch()"
            />
            <label class="form-check-label ml-1" for="check1"
              >Ja schrijf mij in voor de e-mail nieuwsbrief, hou mij op de
              hoogte van het laatste zonnepanelen nieuws, onze aanbiedingen en
              aanbiedingen van partners.</label
            >
            <span
              v-if="!$v.optinCheckboxes.TermsAreRequired && rageClickDidTrigger"
              class="badge badge-danger"
              >Geef akkoord op de voorwaarden.</span
            >
          </div>
        </div>
      </div>
      <AnimatingSubmitButton
        class="btn btn-lg btn-secondary text-white btn-block mt-2 mt-md-3 py-3 py-md-4"
        :disabled="
          submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
        "
        :loading="
          submitStatus === 'SUBMITTING' || submitStatus === 'VALIDATING'
        "
      >
        <span v-if="submitStatus === 'VALIDATING'">Bezig met validatie..</span>
        <span v-else-if="submitStatus === 'SUBMITTING'"
          >Bezig met versturen..</span
        >
        <span v-else>
          <span class=""> Meld mij aan voor de nieuwsbrief </span>
        </span>
      </AnimatingSubmitButton>
    </form>
    <p class="font-weight-bold text-center text-success p-0 m-0 pt-3">
      <font-awesome-icon :icon="['far', 'lock']" class="mr-2" /> Veilige
      SSL-verbinding
    </p>
  </div>
</template>
<script>
import VuexProfileSetGetters from "@/../../shared/vuex-profile-setgetters.js";
import ProfileValidators from "@/../../shared/profile-validators.js";
import LeadFormHandler from "@/../../shared/lead-form-handler.js";
import AnimatingSubmitButton from "@/components/AnimatingSubmitButton.vue";
import LgQuestions from "@/../../shared/lg_questions.js";
import { required } from "vuelidate/lib/validators";
import MailCheck from "@/../../shared/components/Mailcheck.vue";

export default {
  name: "Nieuwsbrief",
  components: {
    AnimatingSubmitButton,
    MailCheck,
  },
  mixins: [VuexProfileSetGetters, LeadFormHandler, LgQuestions],
  data() {
    return {
      autoHideGender: true,
      autoCorrectLastname: true,
      autoCorrectFirstname: true,
      rageClickDidTrigger: false,
    };
  },
  validations() {
    return {
      optinCheckboxes: {
        TermsAreRequired: ProfileValidators.optinCheckboxesMustContain(
          "algemene-voorwaarden"
        ),
      },

      lg_questions: {
        question_1: {
          required,
        },
      },
      email: ProfileValidators.email,
    };
  },
  mounted() {
    this.lgSetQuestionIsText(1);
    this.setLgAnswerOfQuestion(1, "koopwoning");
  },
  methods: {
    rageClickDetected(event, click_count) {
      this.rageClickDidTrigger = true;
      event.target.scrollIntoView(true);
      if (click_count >= 3) {
        setTimeout(() => {
          alert("Er zijn fouten gevonden in het formulier");
        }, 10);
      }
    },

    getSponsorIds() {
      var sponsors = [19];
      return sponsors;
    },
    getTags() {
      if (this.lgAnswerOfQuestion(1) == "huurwoning") {
        return ["BLOKKADE-ANTWOORD", "ZC_POSTCODE"];
      } else {
        return ["ZC_POSTCODE"];
      }
    },
    beforeSubmit() {},
    submitCompleted() {
      this.$router.push({
        name: "thank-you",
      });
      this.$analytics?.fbq?.event("Lead");

      if (this.lgAnswerOfQuestion(1) == "koopwoning") {
        this.$analytics?.fbq?.event("CompleteRegistration");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../styles/includes";

label {
  font-size: 13px;
}

h3 {
  font-size: 2rem;
  line-height: 1;

  @include media-breakpoint-down(md) {
    font-size: 1.3rem;
  }
}
</style>
