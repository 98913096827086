import { required, minLength, maxLength, email, helpers } from 'vuelidate/lib/validators'
const contains = param => value => value.indexOf(param) >= 0;
const NLPostcodeRegex = helpers.regex('NLPostcodeRegex', /^\d{4} ?[a-zA-Z]{2}$/);
const BEPostcodeRegex = helpers.regex('BEPostcodeRegex', /^[1-9][0-9]{3}$/);
const HuisnummerRegex = helpers.regex('HuisnummerRegex', /^\d+/);
const HuisnummerOnlyNumbersRegex = helpers.regex('HuisnummerOnlyNumbersRegex', /^\d*$/);
const UKPostcodeRegex = helpers.regex('UKPostcodeRegex', /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/);
const USPostcodeRegex = helpers.regex('USPostcodeRegex', /^\d{5}(?:-\d{4})?$/);
const ITPostcodeRegex = helpers.regex('ITPostcodeRegex', /^\d{5}$/);
const ESPostcodeRegex = helpers.regex('ESPostcodeRegex', /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/);
const AUPostcodeRegex = helpers.regex('AUPostcodeRegex', /^(?:(?:[2-8]\d|9[0-7]|0?[28]|0?9(?=09))(?:\d{2}))$/);
const DEPostcodeRegex = helpers.regex('DEPostcodeRegex', /^([0]{1}[1-9]{1}|[1-9]{1}[0-9]{1})[0-9]{3}$/);
const InvalidNameCharacters = helpers.regex('InvalidNameCharacters', /^[^><?@!#]*$/);

const minAge = param => function (value) {
    if (!value) {
        return false;
    }
    var x = (value + '').split('-');
    if (x[0] == '0000' || x[1] == '00' || x[2] == '00') {
        return false;
    }
    var d = new Date(parseInt(x[0]), parseInt(x[1]) - 1, parseInt(x[2]));

    var d2 = new Date();
    d2.setFullYear(d2.getFullYear() - param);

    return d2 >= d;
};
const maxAge = param => function (value) {
    if (!value) {
        return false;
    }
    var x = (value + '').split('-');
    if (x[0] == '0000' || x[1] == '00' || x[2] == '00') {
        return false;
    }
    var d = new Date(parseInt(x[0]), parseInt(x[1]) - 1, parseInt(x[2]));

    var d2 = new Date();
    d2.setFullYear(d2.getFullYear() - param);

    return d2 <= d;
};

export default {
    date_of_birth: {
        required,
        validDate: function (a) {
            if (!a) {
                return false;
            }
            var x = (a + '').split('-');
            if (x[0] == '0000' || x[1] == '00' || x[2] == '00') {
                return false;
            }
            var d = new Date(parseInt(x[0]), parseInt(x[1]) - 1, parseInt(x[2]));
            if (d.getMonth() + 1 == parseInt(x[1]) && d.getFullYear() == parseInt(x[0]) && d.getDate() == parseInt(x[2])) {
                return true;
            }
            return false;
        }
    },
    minAge: minAge,
    maxAge: maxAge,
    firstname: {
        required,
        minLength: minLength(2),
        InvalidNameCharacters
    },
    BEPostcode: {
        required,
        minLength: minLength(4),
        maxLength: maxLength(4),
        BEPostcodeRegex
    },
    DEPostcode: {
        required,
        minLength: minLength(5),
        maxLength: maxLength(5),
        DEPostcodeRegex
    },
    UKPostcode: {
        required,
        UKPostcodeRegex
    },
    ITPostcode: {
        required,
        ITPostcodeRegex
    },
    AUPostcode: {
        required,
        AUPostcodeRegex
    },
    USPostcode: {
        required,
        USPostcodeRegex
    },
    ESPostcode: {
        required,
        ESPostcodeRegex
    },
    NLPostcode: {
        required,
        minLength: minLength(6),
        NLPostcodeRegex
    },
    houseNumber: {
        required,
        minLength: minLength(1),
        HuisnummerRegex
    },
    BEhouseNumber: {
        required,
        minLength: minLength(1),
        maxLength: maxLength(6),
        HuisnummerOnlyNumbersRegex
    },
    NLPhoneNumber: {
        required,
        minLength: minLength(10),
        PhoneCheck: function (a) {
            if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
                alert('Validator does not match configured locale');
            }
            if (!a) {
                // in case not required
                return true;
            }

            a = (a + '').replace(/[\-\s\(\)]/, '');
            var b = (a + '').match(/^((0|00310?|\+?31(?:0)?)(\d{9}))$/);
            if (!b || !b[3]) {
                return false;
            }

            if (b[3] == '612345678' || b[3] == '687654321' || b[3] == '600000000') {
                return false;
            }

            if (b[3].match(/^(10|111|113|114|115|117|118|13|14|15|161|162|164|165|166|167|168|172|174|180|181|182|183|184|186|187|20|222|223|224|226|227|228|229|23|24|251|252|255|26|294|297|299|30|313|314|315|316|317|318|320|321|33|341|342|343|344|345|346|347|348|35|36|38|40|411|412|413|416|418|43|45|46|475|478|481|485|486|487|488|492|493|495|497|499|50|511|512|513|514|515|516|517|518|519|521|522|523|524|525|527|528|529|53|541|543|544|545|546|547|548|55|561|562|566|570|571|572|573|575|577|578|58|591|592|593|594|595|596|597|598|599|6([1-58])|70|71|72|73|74|75|76|77|78|79|85|87)/)) {
                return true;
            }
            return false;
        }
    },
    NLPhoneNumberNotRequired: {
        minLength: minLength(10),
        PhoneCheck: function (a) {
            if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
                alert('Validator does not match configured locale');
            }
            if (!a) {
                // in case not required
                return true;
            }

            a = (a + '').replace(/[\-\s\(\)]/, '');
            var b = (a + '').match(/^((0|00310?|\+?31(?:0)?)(\d{9}))$/);
            if (!b || !b[3]) {
                return false;
            }

            if (b[3] == '612345678' || b[3] == '687654321' || b[3] == '600000000') {
                return false;
            }

            if (b[3].match(/^(10|111|113|114|115|117|118|13|14|15|161|162|164|165|166|167|168|172|174|180|181|182|183|184|186|187|20|222|223|224|226|227|228|229|23|24|251|252|255|26|294|297|299|30|313|314|315|316|317|318|320|321|33|341|342|343|344|345|346|347|348|35|36|38|40|411|412|413|416|418|43|45|46|475|478|481|485|486|487|488|492|493|495|497|499|50|511|512|513|514|515|516|517|518|519|521|522|523|524|525|527|528|529|53|541|543|544|545|546|547|548|55|561|562|566|570|571|572|573|575|577|578|58|591|592|593|594|595|596|597|598|599|6([1-58])|70|71|72|73|74|75|76|77|78|79|85|87)/)) {
                return true;
            }
            return false;
        }
    },
    NLMobileNumber: {
        required,
        minLength: minLength(10),
        PhoneCheck: function (a) {
            if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
                alert('Validator does not match configured locale');
            }
            if (!a) {
                // in case not required
                return true;
            }

            a = (a + '').replace(/[\-\s\(\)]/, '');
            var b = (a + '').match(/^((0|00310?|\+?31(?:0)?)(\d{9}))$/);
            if (!b || !b[3]) {
                return false;
            }

            if (b[3] == '612345678' || b[3] == '687654321' || b[3] == '600000000') {
                return false;
            }

            if (b[3].match(/^(6)/)) {
                return true;
            }
            return false;
        }
    },
    BECity: {
        required,
        minLength: minLength(2),
        maxLength: maxLength(25),
        alphaCheck: function (a) {
            if (a.match(/[a-z]/i)) {
                return true;
            }
            return false;
        }
    },
    BEPhoneNumber: {
        required,
        minLength: minLength(9),
        PhoneCheck: function (a) {
            if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_BE' && (this.locale || process.env.VUE_APP_LG_LOCALE) != 'fr_BE') {
                alert('Validator does not match configured locale');
            }
            if (!a) {
                // in case not required
                return true;
            }
            a = (a + '').replace(/[\-\s\(\)]/, '');
            var b = (a + '').match(/^((0|00320?|\+?32(?:0)?)(\d{8,9}))$/);
            if (!b || !b[3]) {
                return false;
            }

            if (b[3].match(/^(10|11|12|13|14|15|16|19|50|51|52|53|54|55|56|57|58|59|60|61|63|64|65|67|68|69|71|80|81|82|83|84|85|86|87|89|2|3|4|6|9|472|473|474|475|476|477|478|479|485|486|494|495|496|497|498|499)/)) {
                return true;
            }
            return false;
        }
    },
    NLPostcodeHouseNumberExt: {
        checkIfExistsInPossibleExt: function (a) {
            if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
                alert('Validator does not match configured locale');
            }
            return this.house_number_possible_ext.indexOf(a) !== -1;
        }
    },
    NLPostcodeAPI: function (a) {
        if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
            alert('Validator does not match configured locale');
        }
        if (!NLPostcodeRegex(a) || !a) {
            return false;
        }
        var zip = a.replace(' ', '');

        return this.axios
            .get("https://postcodeapi.net/" + zip, {
            })
            .then(res => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    BEPostcodeAPI: function (a) {
        if ((this.locale || process.env.VUE_APP_LG_LOCALE).substr(3, 2) != 'BE') {
            alert('Validator does not match configured locale');
        }
        if (!BEPostcodeRegex(a) || !a) {
            return false;
        }
        var zip = a.replace(' ', '');

        return this.axios
            .get("https://postcodeapi.net/be/" + zip, {
            })
            .then(res => {
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    NLPostcodeZipCombination: function (a, b) {
        if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
            alert('Validator does not match configured locale');
        }
        if (!NLPostcodeRegex(a.zip) || !a.zip) {
            return false;
        }
        if (!HuisnummerRegex(a.house_number) || !a.house_number) {
            return false;
        }
        var zip = a.zip.replace(' ', '');
        var house_number_split = (a.house_number + '').match(/^(\d*)[ -\/]?(.*)$/);
        var l_house_number = house_number_split[1];
        var l_house_number_ext = house_number_split[2];

        return this.axios
            .get("https://postcodeapi.net/" + zip + "/" + l_house_number + "/*", {
            })
            .then(res => {
                this.street = res.data.openbareruimte;
                this.house_number = res.data.huisnummer;
                this.city = res.data.woonplaats;
                this.house_number_possible_ext = res.data.toevoegingen;
                this.house_number_possible_pand_info = res.data.panden;

                // check extension
                if (this.house_number_possible_ext.length == 1) {
                    this.house_number_ext = this.house_number_possible_ext[0];
                } else if (l_house_number_ext) {
                    // extension changed or new
                    if (l_house_number_ext == '' && this.house_number_possible_ext.indexOf('empty') !== -1) {
                        this.house_number_ext = 'empty';
                    } else {
                        var x = this.house_number_possible_ext.find(value => value.replace(' ', '').replace('-', '').toLowerCase() == l_house_number_ext.replace(' ', '').replace('-', '').toLowerCase());
                        if (x && x.length) {
                            this.house_number_ext = x;
                        } else {
                            this.house_number_ext = l_house_number_ext;
                        }
                    }
                } else {
                    // check if current extension still exists
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    firstnameExtendedNL: function (firstname) {
        if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
            alert('Validator does not match configured locale');
        }
        if (firstname === "") {
            return false;
        }
        return this.axios
            .get(process.env.VUE_APP_LG_API_BASE + "api/validate/firstname", {
                params: {
                    value: firstname,
                    v: 3,
                }
            })
            .then(res => {
                if (res.data.normalized_name) {
                    if (typeof this.autoCorrectFirstname === "function") {
                        this.autoCorrectFirstname.call(this, res.data.normalized_name);
                    } else if (this.autoCorrectFirstname) {
                        this.firstname = res.data.normalized_name;
                    }
                }
                if (res.data.gender) {
                    if (!this.genderManualEdited) {
                        this.gender = res.data.gender;
                        this.genderUnknown = false;
                    }
                } else {
                    this.genderUnknown = true;
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    lastnameExtendedNL: function (value) {
        if ((this.locale || process.env.VUE_APP_LG_LOCALE) != 'nl_NL') {
            alert('Validator does not match configured locale');
        }
        if (value === "") {
            return false;
        }
        return this.axios
            .get(process.env.VUE_APP_LG_API_BASE + "api/validate/lastname", {
                params: {
                    value: value
                }
            })
            .then(res => {
                if (res.data.normalized_name) {
                    if (typeof this.autoCorrectLastname === "function") {
                        this.autoCorrectLastname.call(this, res.data.normalized_name);
                    } else if (this.autoCorrectLastname) {
                        this.lastname = res.data.normalized_name;
                    }
                }
                return true;
            })
            .catch(() => {
                return false;
            });
    },
    email: {
        required,
        email,
        emailServerValidation: function (value) {
            if (value === "") {
                return false;
            }
            return this.axios
                .get(process.env.VUE_APP_LG_API_BASE + "api/validate/email", {
                    params: {
                        value: value
                    }
                })
                .then(res => {
                    return true;
                })
                .catch(() => {
                    return false;
                });
        },
    },
    lastname: {
        required,
        InvalidNameCharacters
    },
    gender: {
        required
    },
    optinCheckboxesMustContain: contains
}