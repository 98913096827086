export default {
    created() {
    },
    mounted() {
        if (this.$route.query.oa_id || this.$route.query.oa_clickid || this.$route.query.cid || this.$route.query.network || this.$route.query.pid) {
            let query = Object.assign({}, this.$route.query);
            delete query.oa_id;
            delete query.oa_clickid;
            delete query.cid;
            delete query.site;
            delete query.ttid;
            delete query.traffictype;
            delete query.network;
            delete query.pid;
            this.$router.replace({ query });
        }
    }

};